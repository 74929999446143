import { A, D, O, pipe } from "@mobily/ts-belt";
import dayjs from "dayjs";

export type Manager = {
  name: string;
  phone: `${number}-${string}-${string}`;
};

const genHumanName = (): string => {
  return (
    [
      "홍길동",
      "김철수",
      "이영희",
      "박영수",
      "김영희",
      "이철수",
      "박철수",
      "김영수",
      "이철수",
    ][Math.floor(Math.random() * 9)] || "홍길동"
  );
};

const genManager = (): Manager => ({
  name: genHumanName(),
  phone: genDummyPhone(),
});

export type AccountItem = {
  idx: number;
  date: string;
  name: string;
  totalAmount: string;
  phone: `${number}-${string}-${string}`;
  manager: Manager;
  delivery: "직배송" | "택배배송";
};
const genDummyPhone = (): `${number}-${string}-${string}` => {
  const genSymbol = () => ["0", "X", "Z"][Math.floor(Math.random() * 3)];
  const genDigit = () => A.make(4, genSymbol()).join("");
  return `010-${genDigit()}-${genDigit()}`;
};

const namePrefix = [
  "서울",
  "대한",
  "성남",
  "한국",
  "과천",
  "부산",
  "대구",
  "광주",
  "울산",
  "수원",
  "세종",
  "인천",
  "강원",
  "충북",
  "충남",
  "전북",
  "전남",
  "경북",
  "경남",
  "제주",
  "경기",
  "강남",
  "강북",
  "강동",
  "골든",
  "스티브",
];

const nameMiddle = [
  "브론즈",
  "그린",
  "오로라",
  "오메가",
  "푸른",
  "대박",
  "무한",
  "최고",
  "최강",
  "무적",
  "늘푸른",
  "맛있는",
  "드림",
  "도전",
  "유니크",
  "파워",
  "프리미엄",
  "디럭스",
  "행복한",
  "행복",
  "신나",
  "맛있는",
  "달콤한",
  "유쾌한",
];

const namePostfix = [
  "유통",
  "식품",
  "카페",
  "식당",
  "족발",
  "샐러드",
  "치킨",
  "피자",
  "햄버거",
  "반찬",
  "국수",
  "만찬",
  "뷔페",
  "스시",
  "초밥",
  "돈까스",
  "마라탕",
  "양꼬치",
  "라멘",
  "레스토랑",
  "분식",
  "떡볶이",
  "순대",
  "빵집",
  "커피",
  "케이크",
  "차",
  "주스",
  "쥬스",
  "맥주",
];

export const brandNameGen = () => {
  const middle = nameMiddle[Math.floor(Math.random() * nameMiddle.length)];
  const postfix = namePostfix[Math.floor(Math.random() * namePostfix.length)];
  return `${middle} ${postfix}`;
};

export const nameGen = () => {
  const prefix =
    Math.random() > 0.6
      ? namePrefix[Math.floor(Math.random() * namePrefix.length)]
      : "";
  const middle = nameMiddle[Math.floor(Math.random() * nameMiddle.length)];
  const postfix = namePostfix[Math.floor(Math.random() * namePostfix.length)];
  return `${prefix} ${middle} ${postfix}`;
};

const genAccount = (idx: number): AccountItem => {
  return {
    idx,
    date: dayjs().format("YYYY-MM-DD"),
    name: nameGen(),
    totalAmount: `${Intl.NumberFormat("ko-KR").format(
      Math.floor((Math.random() + 0.1) * 10000) * 100
    )}원`,
    phone: genDummyPhone(),
    manager: genManager(),
    delivery: Math.random() > 0.5 ? "직배송" : "택배배송",
  };
};

export function genAccountList(prevState: AccountItem[]): AccountItem[] {
  const lastidx = pipe(
    prevState,
    A.head,
    O.flatMap(D.get("idx")),
    O.getWithDefault(0)
  );

  return [genAccount(lastidx + 1), ...prevState].slice(0, 10);
}

export const genAccountListN = (
  amount: number,
  cur = 0,
  acc: AccountItem[] = []
): AccountItem[] => {
  if (cur >= amount) {
    return acc;
  }
  return genAccountListN(amount, cur + 1, genAccountList(acc));
};

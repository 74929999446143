import { F, O } from "@mobily/ts-belt";
import {
  // DesktopDressRoom,
  // MobileDressRoom,
  type SceneScript,
} from "../Scene";
import { genBrand, genBrandN } from "../desktop/data/Brand";

export const 브랜드상품씬: SceneScript<"브랜드상품"> = {
  playName: "브랜드상품",
  scenes: [
    {
      Mobile: undefined,
      Desktop: {
        screen: "브랜드목록",
        location: ["340px", "-100px"],
      },
      zoom: 0.65,
      autoProceed: {
        delay: 2000,
        updateSceneState: F.identity,
      },
      alias: "브랜드상품:Desktop:브랜드목록: 상품 수 점멸",
      duration: "1000ms",
      mainActor: "유통사",
      label: undefined,
    },
    {
      Mobile: undefined,
      Desktop: {
        screen: "브랜드상품관리",
        location: ["340px", "-100px"],
      },
      zoom: 0.65,
      autoProceed: {
        delay: 3000,
        updateSceneState: F.identity,
      },
      alias: "브랜드상품:Desktop:브랜드상품관리: 서비스관리 메뉴 클릭",
      duration: "1000ms",
      mainActor: "유통사",
      label: undefined,
    },
  ],
  initialSceneState: {
    냉동감자_개수: 1,
    데스크탑_주문관리_메뉴: "접힘",
    공지사항_문구: "",
    데스크탑_서비스관리_메뉴: "접힘",
    모바일_공지사항: "접힘",
    브랜드: O.Some([genBrand(), genBrand(), ...genBrandN(15)]),
  },
};

import { 링크 } from "./데이터/링크";
import { useTracker } from "./util/Tracker";
import { useContext, useState } from "react";
import { R } from "@mobily/ts-belt";
import { FormField, phoneFormatter } from "./FormField";
import type { Form, DefaultFormType } from "./util/FormType";
import styles from "./랜딩_섹션1_Desktop3.module.css";
import { asx } from "./util/applyStyle";
import router from "next/router";
import {
  CUSTOMTER_CATEGORY,
  CustomerCategoryCTX,
  type CustomerCategory,
} from "./util/CustomerCategoryCTX";
import { UISection } from "./랜딩_섹션1_Desktop3_Animation";

const as = asx(styles);

export type 간편가입_폼타입 = "phone";
type 간편가입RequiredField = "phone";
type 간편가입FormState = Form<간편가입RequiredField, undefined, true>;
type 간편가입FormDefaultState = DefaultFormType<
  간편가입RequiredField,
  undefined,
  true
>;

const defaultForm: 간편가입FormDefaultState = {
  phone: R.Error(""),
  privacyAgree: true,
  __state: "error",
};

const makeFormState = (phone: R.Result<string, string>): 간편가입FormState => {
  return {
    phone,
    privacyAgree: true,
    __state: R.isOk(phone) ? "ok" : "error",
  };
};

const CTA = () => {
  const [formValues, setFormValues] = useState<간편가입FormState>(defaultForm);
  const tracker = useTracker();
  const onClick = async () => {
    tracker.trackingClick({
      depth1: "HOME",
      depth2: "회원가입",
      depth3: "마켓봄",
      indicator: "회원가입",
      scroll: window.scrollY,
    });

    router.push(
      `${링크.마켓봄_간편가입_실험_링크_PC}/?hp=${formValues.phone._0}`
    );
  };

  const onSubmit = () => {
    if (formValues.__state === "ok") {
      onClick();
    }
  };

  return (
    <div className={styles["subHeader"]}>
      <div className={as("simple-signup  bb-[3] b-[0]")}>
        <div style={{ flex: 1, paddingLeft: "12px" }}>
          <FormField<간편가입_폼타입>
            label=""
            errorLabel=""
            fieldName="phone"
            validateFunction="mobilePhone"
            onUpdate={(newValue) => {
              setFormValues(() => makeFormState(newValue));
              console.log(formValues);
            }}
            placeholder="010-0000-0000"
            formatter={phoneFormatter}
            onEnter={onSubmit}
            styles={styles}
          />
        </div>

        <button onClick={onClick} className={as("submit")}>
          5초 만에 시작하기
        </button>
      </div>
    </div>
  );
};

type CustomerCategoryToggleProps = {
  targetCustomerCategory: CustomerCategory;
  onClick: (cc: CustomerCategory) => void;
  selected: boolean;
};
const CustomerCategoryToggle = ({
  targetCustomerCategory,
  onClick,
  selected,
}: CustomerCategoryToggleProps) => {
  return (
    <button
      onClick={() => onClick(targetCustomerCategory)}
      style={{
        padding: "4px 18px 6px 18px",
        fontSize: "20px",
        fontWeight: "bold",
        borderRadius: "20px",
        boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.15)",
        transition: "all 0.5s cubic-bezier(0.2,0.8,0.2,1)",
        ...(selected
          ? {
            border: "2px solid #533B85",
            background: "#533B85",
            color: "white",
          }
          : {
            color: "rgba(83, 59, 133, 0.50)",
            border: "2px solid transparent",
            background: "rgba(255, 255, 255, 0.30)",
          }),
      }}
    >
      {targetCustomerCategory}
    </button>
  );
};

type CustomerCategoryToggleGroupProps = {
  currentValue: CustomerCategory;
  onClick: (cc: CustomerCategory) => void;
};

const CustomerCategoryToggleGroup = ({
  currentValue,
  onClick,
}: CustomerCategoryToggleGroupProps) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "6px",
        alignItems: "center",
        marginBottom: "12px",
      }}
    >
      {CUSTOMTER_CATEGORY.map((cc) => (
        <CustomerCategoryToggle
          targetCustomerCategory={cc}
          onClick={onClick}
          selected={cc === currentValue}
          key={cc}
        />
      ))}
      <span style={{ fontSize: "24px", color: "#333", fontWeight: "bold" }}>
        을 위한
      </span>
    </div>
  );
};

export const 랜딩_섹션1_Desktop3 = () => {
  // const tracker = useTracker();
  const { customerCategory, setCustomerCategory } =
    useContext(CustomerCategoryCTX);

  const gradientOffset: Record<CustomerCategory, [number, number]> = {
    유통사: [5.91, 157.75],
    "프랜차이즈 본사": [-5, 147.75],
    중도매인: [-15, 137.75],
  };

  return (
    <>
      <div
        className="section"
        style={{
          background: `linear-gradient(114deg, rgba(141, 139, 240, 0.50) ${gradientOffset[customerCategory][0]}%, rgba(255, 110, 79, 0.50) ${gradientOffset[customerCategory][1]}%)`,
        }}
      >
        <div
          className="inner"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "65px",
            paddingBottom: "543px",
          }}
        >
          <CustomerCategoryToggleGroup
            currentValue={customerCategory}
            onClick={(newValue) => {
              setCustomerCategory(newValue);
            }}
          />
          <dl
            style={{
              color: "#333",
              textAlign: "center",
              fontSize: "60px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "130%",
              letterSpacing: "-1.8px",
              marginBottom: "32px",
            }}
          >
            <dd>
              세상에서 가장 간편한
              <br />
              수발주 마켓봄
            </dd>
          </dl>
          {
            <div className={as("cta-wrap")}>
              <CTA />
            </div>
          }
          <UISection />
        </div>
      </div>
    </>
  );
};

import { useContext, useRef, useState } from "react";
import { 회사정보Context } from "./데이터/회사정보";
import { pipe, A, S, O, R } from "@mobily/ts-belt";
import { P, match } from "ts-pattern";
import { phoneFormatter } from "./FormField";
import { useTracker } from "./util/Tracker";

import 전화아이콘 from "/public/assets/mobile/images/icon/icon_tell1.svg";
import 이메일아이콘 from "/public/assets/mobile/images/icon/icon_letter1.svg";
import 위치_아이콘 from "/public/assets/mobile/images/icon/icon_location1.svg";
import { FormParseSet } from "./api/apiForm";
import Link from "next/link";
import { 링크 } from "./데이터/링크";
import Image from "next/image";
import CheckedIcon from "/public/assets/mobile/images/icon/icon_checkbox_on1.svg";
import UnCheckedIcon from "/public/assets/mobile/images/icon/icon_checkbox_off1.svg";
import style from "./랜딩_섹션9_Mobile3.module.css";
import { asx } from "./util/applyStyle";
const as = asx(style);

export const 랜딩_섹션9_Mobile = () => {
  const 회사정보 = useContext(회사정보Context);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [contents, setContents] = useState("");
  const [privacyAgreement, setPrivacyAgreement] = useState(true);
  const isLoading = useRef(false);
  const tracker = useTracker();

  const apply = async () => {
    const [name_, phoneNumber_, contents_] = pipe(
      [name, phoneNumber, contents],
      A.map(S.trim),
      A.toTuple
    );
    match([
      FormParseSet["validName"](name_ ?? ""),
      FormParseSet["phone"](phoneNumber_ ?? ""),
      FormParseSet["notEmptyString"](contents_ ?? ""),
      privacyAgreement,
    ] as [
      R.Result<string, string>,
      R.Result<string, string>,
      R.Result<string, string>,
      boolean
    ])
      .with(
        [{ TAG: 0 }, { TAG: 0 }, { TAG: 0 }, true],

        async ([name, phoneNumber, contents]) => {
          if (isLoading.current) {
            alert("문의사항을 전송 중입니다.");
            return;
          }
          const body = new URLSearchParams();
          body.append("vendor_name", name._0 ?? "");
          body.append("mobile", phoneNumber._0 ?? "");
          body.append("contents", contents._0 ?? "");
          isLoading.current = true;

          fetch("/gate.api/question", {
            method: "POST",
            body,
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error(
                  "현재 상담 접수가 어려워 전화를 주시면 빠른 안내 해드리겠습니다."
                );
              }
            })
            .then(
              () => {
                alert("문의사항이 전송되었습니다.");
                setName("");
                setPhoneNumber("");
                setContents("");
              },
              (error) => {
                alert(error.message);
              }
            )
            .finally(() => {
              isLoading.current = false;
            });
        }
      )
      .with([P._, P._, P._, false], () => {
        alert(`${"개인정보 처리방침에 동의해주세요."}`);
      })
      .otherwise(([name, phoneNumber, contents]) => {
        const missedMessage = pipe(
          [
            FormParseSet["notEmptyString"](name._0 ?? ""),
            FormParseSet["notEmptyString"](phoneNumber._0 ?? ""),
            FormParseSet["notEmptyString"](contents._0 ?? ""),
          ],
          A.zip(["이름", "연락처", "내용"]),
          A.filterMap(([value, label]) =>
            R.isError(value) ? O.Some(label) : O.None
          ),
          A.join(", "),
          O.fromPredicate((x) => S.trim(x) !== ""),
          O.map(
            (missedLabels) => `[ ${missedLabels} ]\n\n필수 입력 항목입니다.`
          ),
          O.getWithDefault("")
        );
        const invalidPhoneMessage = pipe(
          FormParseSet["phone"](phoneNumber._0 ?? ""),
          O.fromPredicate(R.isError),
          O.map(() => "연락처가 유효하지 않습니다"),
          O.getWithDefault("")
        );

        const invalidNameMessage = pipe(
          FormParseSet["validName"](name._0 ?? ""),
          O.fromPredicate(R.isError),
          O.map(() => "이름이 유효하지 않습니다"),
          O.getWithDefault("")
        );
        const message = pipe(
          [missedMessage, invalidNameMessage, invalidPhoneMessage],
          A.join("\n\n")
        );
        alert(`${message}`);
      });
  };

  return (
    <div className={as("section section9")} id="contact">
      <h3>유통관리 서비스에 관한 모든 궁금증을</h3>
      <p className={as("desc")}>지금 문의하세요.</p>
      <div className={as("insert-area")}>
        <div className={as("row")}>
          <strong className={as("label")}>이름</strong>
          <label>
            <input
              type="text"
              value={name}
              placeholder="회사명 또는 담당자 이름"
              onChange={(e) => setName(e.target.value)}
            />
          </label>
        </div>
        <div className={as("row")}>
          <strong className={as("label")}>연락처</strong>
          <label>
            <input
              type="text"
              value={phoneNumber}
              placeholder="담당자 연락처"
              onChange={(e) =>
                setPhoneNumber(pipe(e.target.value, phoneFormatter))
              }
            />
          </label>
        </div>
        <div className={as("row")}>
          <strong className={as("label")}>문의내용</strong>
          <label>
            <textarea
              value={contents}
              onChange={(e) => setContents(e.target.value)}
              placeholder={
                "내용을 적어주시면 사업 맞춤 상담& 혜택을 드립니다.\n업종 : 유통사 / 프랜차이즈본사 / 물류사 등\n현재 수발주 방식 : 카톡, 전화, 타사 프로그램, ERP 등"
              }
            ></textarea>
          </label>
        </div>
        <div
          className={as("row")}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            height: "20px",
          }}
        >
          <button
            onClick={() => {
              setPrivacyAgreement(!privacyAgreement);
            }}
          >
            <Image
              src={privacyAgreement ? CheckedIcon : UnCheckedIcon}
              width={20}
              height={20}
              alt={""}
            />
          </button>
          <div
            style={{
              display: "inline-flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <span style={{ wordBreak: "keep-all", textAlign: "start" }}>
              {"개인정보 처리방침에 동의합니다. "}
            </span>
            <Link
              href={링크.마켓봄_개인정보처리방침}
              style={{ opacity: "0.6" }}
              target="_blank"
            >
              {"[상세보기]"}
            </Link>
          </div>
        </div>
        <div className={as("btn-wrap")}>
          <button
            type="button"
            className={as("btn-send")}
            onClick={() => {
              apply();
              tracker.trackingClick({
                depth1: "HOME",
                depth2: "문의하기전송",
                indicator: "리드 전송 시도",
              });
            }}
          >
            문의사항 전송하기
          </button>
        </div>
      </div>
      <div className={as("contact")}>
        <div>
          <div className={as("tel")}>
            <span
              style={{
                width: "32px",
                height: "32px",
                background: `url(${전화아이콘.src}) no-repeat center center`,
                backgroundSize: "32px 32px",
                marginRight: "6px",
              }}
            />
            <strong>{회사정보.회사전화번호}</strong>
            <span>{회사정보.문의가능시간_점심시간제외}</span>
          </div>
        </div>
        <div className={as("email")}>
          <span
            style={{
              display: "inline-block",
              width: "32px",
              height: "32px",
              background: `url(${이메일아이콘.src}) no-repeat center center`,
              backgroundSize: "32px 32px",
              marginRight: "6px",
            }}
          />
          {회사정보.이메일}
        </div>
        <div className={as("location")}>
          <span
            style={{
              display: "inline-block",
              width: "32px",
              height: "32px",
              background: `url(${위치_아이콘.src}) no-repeat center center`,
              backgroundSize: "32px 32px",
              marginRight: "6px",
            }}
          />
          <div className={as("infoText")}>
            {회사정보.주소.건물주소}
            <br />
            {회사정보.주소.상세주소}
          </div>
        </div>
      </div>
    </div>
  );
};

import { useTracker } from "./util/Tracker";
import { 링크 } from "./데이터/링크";
import { 리뷰_목록, type 고객사_리뷰 } from "./데이터/고객사리뷰";
import { BoldableTextMultiline } from "./BoldableText";
import { A, O, pipe } from "@mobily/ts-belt";
import styles from "./랜딩_섹션2_Desktop3.module.css";
import youtubeMini from "/public/assets/pc/images/pic/youtubemini.png";
import { asx } from "./util/applyStyle";
import Image from "next/image";
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { CustomerCategoryCTX } from "./util/CustomerCategoryCTX";

const as = asx(styles);

const 리뷰 = ({ 리뷰 }: { 리뷰: 고객사_리뷰 }) => {
  return (
    <div
      style={{
        background: "#FFF",
        borderRadius: "10px",
        boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.15)",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "20px 24px",
        fontSize: "20px",
        gap: "10px",
        width: "458px",
        height: "130px",
      }}
    >
      <BoldableTextMultiline list={리뷰.text} align={"left"} />
      <span
        style={{
          color: "#8D8D8D",
          fontSize: "16px",
          lineHeight: "140%",
        }}
      >
        - {리뷰.name}
      </span>
    </div>
  );
};

const 리뷰목록 = () => {
  const { customerCategory } = useContext(CustomerCategoryCTX);

  const 현재_고객_대상_리뷰_목록 = pipe(
    리뷰_목록,
    A.filter(({ customerCategory: reviewCustomerCategory }) =>
      reviewCustomerCategory.includes(customerCategory)
    )
  );
  return (
    <div
      style={{
        width: "518px",
        height: "438px",
        overflow: "hidden",
        paddingLeft: "0px 20px",
      }}
    >
      <div
        className={as(`reviews ${customerCategory}`)}
        style={{
          display: "inline-flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        {pipe(
          현재_고객_대상_리뷰_목록,
          A.last,
          O.map((고객사_리뷰_) => (
            <리뷰 리뷰={고객사_리뷰_} key={고객사_리뷰_.name} />
          )),
          O.getWithDefault(<></>)
        )}
        {pipe(
          현재_고객_대상_리뷰_목록,
          A.map((고객사_리뷰_) => (
            <리뷰 리뷰={고객사_리뷰_} key={고객사_리뷰_.name} />
          ))
        )}
        {pipe(
          현재_고객_대상_리뷰_목록,
          A.take(5),
          A.map((고객사_리뷰_) => (
            <리뷰 리뷰={고객사_리뷰_} key={고객사_리뷰_.name + "2"} />
          ))
        )}
      </div>
    </div>
  );
};

export const 랜딩_섹션2_Desktop_Skeleton = forwardRef<HTMLDivElement>(
  function Renderer(_props, ref) {
    const tracker = useTracker();
    return (
      <div
        className="section section2"
        id="section2"
        style={{ background: "#ECE9F1" }}
        ref={ref}
      >
        <div className="inner">
          <dl
            style={{
              marginBottom: "64px",
              color: "#333",
              textAlign: "center",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "40px",
              letterSpacing: "-2px",
            }}
          >
            <div
              style={{
                fontSize: "24px",
                fontWeight: "normal",
                marginBottom: "6px",
              }}
            >
              마켓봄 도입으로 업무 효율을 높인
            </div>
            <div>고객사 성공 사례</div>
          </dl>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                className="pic"
                style={{
                  height: "376px",
                  overflow: "hidden",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.15)",
                  marginBottom: "12px",
                }}
              >
                <iframe
                  width="680"
                  height="376"
                  src={`https://www.youtube.com/embed/${링크.랜딩_영상}`}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ border: 0 }}
                  title={"마켓봄 성공 사례 인터뷰"}
                  onClick={() => {
                    tracker.trackingClick({
                      depth1: "HOME",
                      depth2: "마켓봄 소개 영상",
                    });
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Image src={youtubeMini} alt={""} />
                <span
                  style={{
                    color: " #333",
                    fontSize: " 24px",
                    fontStyle: " normal",
                    fontWeight: " 500",
                    lineHeight: " 34px",
                    letterSpacing: " -1.2px",
                  }}
                >
                  [한누리푸드 유통사 인터뷰] 마켓봄으로 미수금 완벽 해결!
                </span>
              </div>
            </div>
            <리뷰목록 />
          </div>
        </div>
      </div>
    );
  }
);

export const 랜딩_섹션2_Desktop = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [fireOnce, setFireOnce] = useState(false);
  const tracker = useTracker();

  useEffect(() => {
    const 성공사례listener = () => {
      setIsVisible(window.scrollY > (ref.current?.offsetTop ?? 99999));
    };

    window.addEventListener("scroll", 성공사례listener);
    return () => {
      window.removeEventListener("scroll", 성공사례listener);
    };
  }, [ref, isVisible]);

  useEffect(() => {
    console.log(fireOnce, isVisible);
    if (!fireOnce && isVisible) {
      tracker.trackingScroll({
        depth1: "HOME",
        depth2: "성공사례",
      });
      setFireOnce(true);
    }
  }, [isVisible, fireOnce, tracker]);

  return <랜딩_섹션2_Desktop_Skeleton ref={ref} />;
};

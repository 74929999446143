import { BoldableText, BoldableTextMultiline } from "../../BoldableText";
import { asx } from "../../util/applyStyle";
import type { ActorLocation, Pixel } from "../Scene";
import * as Actor from "../Actors";
import styles from "./LabelActor.module.css";

const as = asx(styles);

export type LabelActorProps = {
  text: BoldableText[][];
  location: ActorLocation;
  size: Pixel;
  align: "left" | "center" | "right";
};
export const LabelActor = ({
  text,
  location,
  size,
  align,
}: LabelActorProps) => {
  return (
    <div
      className={as("labelActor")}
      style={{
        ...Actor.parseOrder(location, 1, "500ms"),
        fontSize: size,
        left: "50%",
        top: "50%",
        position: "absolute",
        opacity: "0.6",
        fontWeight: "bold!important",
      }}
    >
      <BoldableTextMultiline list={text} align={align} />
    </div>
  );
};

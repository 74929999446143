import { O } from "@mobily/ts-belt";
import type { SceneScript } from "../Scene";

export const 주문앱씬: SceneScript<"주문앱"> = {
  playName: "주문앱",
  scenes: [
    {
      Mobile: {
        screen: "메인홈",
        location: ["-100px", "120px"],
      },
      Desktop: O.None,
      autoProceed: {
        delay: 3000,
        updateSceneState: O.None,
      },
      zoom: 0.8,

      alias: "주문앱:Mobile:메인홈: 직배송 버튼 클릭",
      duration: "1000ms",
      label: O.None,
      mainActor: "식당",
    },
    {
      Mobile: {
        screen: "PLP",
        location: ["-100px", "-20px"],
      },
      Desktop: O.None,
      zoom: 0.9,
      autoProceed: {
        delay: 3000,
        updateSceneState: O.None,
      },
      alias: "주문앱:Mobile:PLP: 냉동 감자의 수량 조절과 담기 버튼 클릭",
      duration: "1000ms",
      mainActor: "식당",
      label: O.None,
    },
  ],
  initialSceneState: {
    냉동감자_개수: 1,
    데스크탑_주문관리_메뉴: "접힘",
    공지사항_문구: "",
    데스크탑_서비스관리_메뉴: "접힘",
    모바일_공지사항: "접힘",
    브랜드: undefined,
  },
};

import styles from "./MockMarketbom.module.css";
import { asx } from "../../util/applyStyle";

import brandProductUpperDummy from "/public/assets/pc/images/pic/mock_brandProductList_1.png";
import brandProductInnerDummy from "/public/assets/pc/images/pic/mock_brandProductListInner.png";
import bookmarkEmpty from "/public/assets/pc/images/icon/mock_bookmark_empty.svg";
import bookmarkFilled from "/public/assets/pc/images/icon/mock_bookmark_filled.svg";
import Image from "next/image";
import type { BrandProductList } from "./data/BrandProductList";
import { useEffect, useMemo, useState } from "react";

const as = asx(styles);

const BrandProductListTableHeader = () => {
  return (
    <div className={as("headerRow")}>
      <div className={as("col")}>추천</div>
      <div className={as("col")}>no</div>
      <div className={as("col")}>상품코드</div>
      <div className={as("col")}>상품명</div>
      <div className={as("col")}>카테고리</div>
      <div className={as("col")}>
        규격
        <br />
        (단위)
      </div>
      <div className={as("col")}>
        제조사
        <br />
        (원산지)
      </div>
      <div className={as("col")}>배송유형</div>
      <div className={as("col")}>직배송 단가</div>
      <div className={as("col")}>택배배송 단가</div>
    </div>
  );
};

const Bookmark = ({ enabled }: { enabled: boolean }) =>
  useMemo(() => {
    return (
      <Image
        src={enabled ? bookmarkFilled : bookmarkEmpty}
        className={enabled ? as("blinkBookMark") : ""}
        alt=""
      />
    );
  }, [enabled]);

const BrandProductListTable = ({
  data,
}: {
  data: readonly BrandProductList[];
}) => {
  const [indicator, setIndicator] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndicator((prev) => prev + 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  return useMemo(() => {
    return (
      <div className="" suppressHydrationWarning>
        {data.map((x, i) => {
          return (
            <div key={i} className={as("tableRow")}>
              <div className={as("col")}>
                <Bookmark enabled={indicator > i} />
              </div>
              <div className={as("col")}>{i + 1}</div>
              <div className={as("col")}>{x.code}</div>
              <div className={as("col")}>{x.name}</div>
              <div className={as("col")}>{x.category}</div>
              <div className={as("col")}>{x.unit}</div>
              <div className={as("col")}>{x.origin}</div>
              <div className={as("col")}>직배송</div>
              <div className={as("col")}>
                {Intl.NumberFormat("ko-KR").format(
                  Math.floor((Math.random() + 0.1) * 100) * 1000
                )}
                원
              </div>
              <div className={as("col")}>0원</div>
            </div>
          );
        })}
      </div>
    );
  }, [data, indicator]);
};

export type BrandProductListPageProps = {
  brandName: string;
  productList: readonly BrandProductList[];
};

export const BrandProductListPage = ({
  brandName,
  productList,
}: BrandProductListPageProps) => {
  return (
    <div className={as("brandProduct")}>
      <Image src={brandProductUpperDummy} alt={""} width={1250} />
      <div className={as("brandProductList")}>
        <div className={as("brandProductListHeader")}>상품목록</div>
        <div className={as("brandName") + " bold"}>{brandName}</div>
        <div className={as("totalItems")}>{`전체 ${productList.length}개`}</div>
        <div className={as("tableArea")}>
          <Image src={brandProductInnerDummy} alt="" width={980} />
          <div className={as("tableContent")}>
            <BrandProductListTableHeader />

            <BrandProductListTable data={productList} />
          </div>
        </div>
      </div>
    </div>
  );
};

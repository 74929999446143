import Image from "next/image";
import Link from "next/link";
import GooglePlay from "/public/assets/pc/images/pic/img_googleplay1.png";
import AppStore from "/public/assets/pc/images/pic/img_appstore1.png";
import { useTracker } from "./util/Tracker";

import { 링크 } from "./데이터/링크";

export const 랜딩_섹션5_Desktop = () => {
  const tracker = useTracker();
  return (
    <div className="section section5">
      <div className="inner">
        <div className="store-box">
          <div className="col">
            <h4>거래처(식당)용 발주 전용 앱 다운로드</h4>
            <div className="btn-wrap">
              <Link
                href={링크.거래채앱_구글플레이}
                className="btn-googleplay"
                onClick={() =>
                  tracker.trackingClick({
                    depth1: "HOME",
                    depth2: "구글플레이거래처앱",
                  })
                }
              >
                <Image
                  src={GooglePlay}
                  width={220}
                  alt="구글 플레이스토어에서 거래처 발주 전용 앱 다운받기"
                />
              </Link>
              <Link
                href={링크.거래채앱_앱스토어}
                className="btn-appstore"
                onClick={() =>
                  tracker.trackingClick({
                    depth1: "HOME",
                    depth2: "앱스토어거래처앱",
                  })
                }
              >
                <Image
                  src={AppStore}
                  alt="애플 앱스토어에서 거래처 발주 전용 앱 다운받기"
                  width={220}
                />
              </Link>
            </div>
          </div>
          <div className="col">
            <h4>유통사용 전용 앱 다운로드</h4>
            <div className="btn-wrap">
              <Link
                href={링크.유통사앱_구글플레이}
                className="btn-googleplay"
                onClick={() =>
                  tracker.trackingClick({
                    depth1: "HOME",
                    depth2: "구글플레이유통사앱",
                  })
                }
              >
                <Image
                  src={GooglePlay}
                  alt="구글 플레이스토어에서 유통사 전용 앱 다운받기"
                  width={220}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

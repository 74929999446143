import * as Actor from "../Actors";
import styles from "./MockMarketbom.module.css";
import { asx } from "../../util/applyStyle";
import { match } from "ts-pattern";
import logo from "/public/assets/pc/logo.svg";
import Image from "next/image";
import type { SceneContext } from "../Scene";
import chevronDown from "/public/assets/pc/images/icon/chevron_down.svg";
import filterDummy from "/public/assets/pc/images/pic/mock_orderlist_filter.png";
import orderDummy1 from "/public/assets/pc/images/pic/mock_order_dummy.png";
import orderDummy2 from "/public/assets/pc/images/pic/mock_order_dummy2.png";
import orderDummy3 from "/public/assets/pc/images/pic/mock_order_dummy3.png";
import brandList from "/public/assets/pc/images/pic/mock_brandList.png";
import accountListDummy from "/public/assets/pc/images/pic/mock_accountList.png";
import accountListTotalDummy from "/public/assets/pc/images/pic/mock_accountList_total.png";
import { O, pipe } from "@mobily/ts-belt";
import dayjs from "dayjs";
import { orderList, type OrderListTable } from "./data/OrderList";

import { MockDashBoard } from "./MockDashBoard";
import { BrandProductListPage } from "./MockBrandProductList";
import { type AccountItem } from "./data/AccountList";
import { useEffect, useMemo, useState } from "react";
import { CSR } from "../../CSR";
import type { Brand } from "./data/Brand";

const as = asx(styles);
type SideBarItemProps = {
  name: string;
  interactable?: {
    sceneContext: SceneContext;
    proceedScene: O.Option<() => void>;
    updateSceneContext: () => void;
    folded: boolean;
  };
};
const SibeBarItem = ({ name, interactable }: SideBarItemProps) => {
  return (
    <div
      className={as(
        `item ${O.isSome(interactable?.proceedScene) ? "clickMe" : ""}`
      )}
      onClick={() => {
        interactable?.updateSceneContext && interactable?.updateSceneContext();
        interactable?.proceedScene && interactable.proceedScene();
      }}
    >
      <span>{name}</span>
      <Image
        src={chevronDown}
        alt=""
        className={as(`${interactable?.folded ?? true ? "" : "expanded"}`)}
      />
    </div>
  );
};
const SideBar = (props: Actor.ActorProps<"Desktop">) => {
  const menuList = [
    "주문 관리",
    "매입 관리",
    "상품 관리",
    "단가 관리",
    "재고 관리",
    "거래처 관리",
    "브랜드 관리",
    "원장 관리",
    "통계",
    "회계 관리",
    "서비스 관리",
  ];

  return (
    <div className={as("SideBar")}>
      <div className={as("header")}>
        <div className={as("logo")}>
          <Image
            src={logo}
            alt={""}
            style={{ filter: "saturate(0%) brightness(999%)" }}
            width={106}
            height={22.6}
          />
        </div>
        <div className={as("user")}>
          <div className={as("유통사")}>△△유통</div>
          <div className={as("사업자정보")}>사업자정보 보기</div>
        </div>
      </div>
      <div className={as("list")}>
        <SibeBarItem
          name={"주문관리"}
          interactable={{
            proceedScene:
              props.sceneAlias ===
              "수발주취합:Desktop:대시보드: 주문관리 메뉴 클릭"
                ? O.Some(props.proceedScene)
                : O.None,

            sceneContext: props.sceneContext,
            updateSceneContext: () => {
              props.updateSceneContext({
                ...props.sceneContext,
                데스크탑_주문관리_메뉴: "펼침",
              });
            },
            folded: props.sceneContext.데스크탑_주문관리_메뉴 === "접힘",
          }}
        />
        <div
          className={as(
            `sideBarSubMenu ${
              props.sceneContext.데스크탑_주문관리_메뉴 === "펼침"
                ? "show"
                : "hide"
            }`
          )}
        >
          <div className={as("selected")}>• 주문 목록</div>
          <div>• 상품별 주문목록</div>
          <div>• 반품 내역</div>
          <div>• 주문 설정</div>
        </div>
        {menuList.slice(1, menuList.length - 1).map((name) => (
          <SibeBarItem name={name} key={name} />
        ))}
        <SibeBarItem
          name={"서비스관리"}
          interactable={{
            proceedScene:
              props.sceneAlias ===
              "공지사항:Desktop:대시보드: 서비스관리 메뉴 클릭"
                ? O.Some(props.proceedScene)
                : O.None,
            sceneContext: props.sceneContext,
            updateSceneContext: () => {
              props.updateSceneContext({
                ...props.sceneContext,
                데스크탑_서비스관리_메뉴: "펼침",
              });
            },
            folded: props.sceneContext.데스크탑_서비스관리_메뉴 === "접힘",
          }}
        />
        <div
          className={as(
            `sideBarSubMenu ${
              props.sceneContext.데스크탑_서비스관리_메뉴 === "펼침"
                ? "show"
                : "hide"
            }`
          )}
        >
          <div className={as("selected")}>• 공지 관리</div>
          <div>• 상품별 주문목록</div>
          <div>• 반품 내역</div>
          <div>• 주문 설정</div>
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div className={as("titleRow")}>
      <div className={as("chunk")}>
        <span>마켓봄 사용법</span>
      </div>
      <div className={as("chunk")}>
        <span>관리자님 정보 보기</span>
        <span>이용요금 결제</span>
        <span>매뉴얼</span>
        <span>로그아웃</span>
      </div>
    </div>
  );
};

const MockCheckbox = () => {
  return <div className={as("checkbox")} />;
};

const TableRow = ({
  no,
  거래처,
  결제수단,
  배송요청일,
  주문금액,
  주문번호,
  주문일시,
  총_주문수량,
  className,
}: OrderListTable) => {
  return (
    <div className={as(`item ${className}`)}>
      <MockCheckbox />
      <div className={as("col")}>{no}</div>
      <div className={as("col")}>{주문일시}</div>
      <div className={as("col")}>{배송요청일}</div>
      <div className={as("col")}>{주문번호}</div>
      <div className={as("col")}>{거래처}</div>
      <div className={as("col")}>직배송</div>
      <div className={as("col")}>{총_주문수량}</div>
      <div className={as("col")}>{결제수단}</div>
      <div className={as("col")}>{주문금액}</div>
      <div className={as("col")}>주문완료</div>
    </div>
  );
};
const Table = (props: Actor.ActorProps<"Desktop">) => {
  const [now, setNow] = useState(dayjs());

  useEffect(() => {
    setNow(dayjs());
  }, []);

  return (
    <div className={as("table")}>
      <div className={as("headerRow")}>
        <MockCheckbox />
        <div className={as("col")}>no</div>
        <div className={as("col")}>주문일시</div>
        <div className={as("col")}>배송요청일</div>
        <div className={as("col")}>주문번호</div>
        <div className={as("col")}>거래처</div>
        <div className={as("col")}>배송유형</div>
        <div className={as("col")}>총 주문수량</div>
        <div className={as("col")}>결제수단</div>
        <div className={as("col")}>주문금액</div>
        <div className={as("col")}>주문상태</div>
      </div>
      <div className={as("contentRow")}>
        <div
          className={as(
            `innerRow ${
              props.sceneAlias ===
              "수발주취합:Desktop:주문목록: 새 주문 항목이 보여짐"
                ? "showNewRow"
                : "hideNewRow"
            } `
          )}
        >
          <TableRow
            no={5}
            주문일시={now.format("YYYY-MM-DD\nhh:mm:ss")}
            배송요청일={now.add(1, "day").format("YYYY-MM-DD")}
            주문번호={`${btoa(now.toString()).slice(0, 16)}`}
            거래처={"OO식당"}
            총_주문수량={props.sceneContext.냉동감자_개수}
            결제수단="신용카드"
            주문금액={`${Intl.NumberFormat("en-US").format(
              23000 * props.sceneContext.냉동감자_개수
            )}원`}
            className={"emphasis"}
          />
          {orderList.map((item) => (
            <TableRow {...item} key={item.no} />
          ))}
        </div>
      </div>
    </div>
  );
};

export const OrderList = (props: Actor.ActorProps<"Desktop">) => {
  return (
    <div className={as("orderList")}>
      <Image
        src={filterDummy}
        alt={""}
        height={191}
        style={{ marginBottom: "40px" }}
      />
      <Image
        src={orderDummy1}
        alt={""}
        height={40}
        style={{ marginBottom: "8px" }}
      />
      <div style={{ display: "flex" }}>
        <Image src={orderDummy3} alt={""} height={70} />
        <Image src={orderDummy2} alt={""} height={70} />
      </div>
      <Table {...props} />
    </div>
  );
};

// export const NotificationList = () => {
//   return (
//     <div className={as("notification")}>
//       <Image src={notificationListDummy} alt={""} width={1250} />
//     </div>
//   );
// };
// export const NotificationAdd = () => {
//   return (
//     <div className={as("notification")}>
//       <Image src={notificationAddDummy} alt={""} width={1250} />
//       <div className={as("typewriter")}>
//         {"10/1부터 주문마감시간을 오후 9시로 연장합니다."}
//       </div>
//     </div>
//   );
// };

const AccountListTableHeader = () => {
  return (
    <div className={as("headerRow")}>
      <div className={as("col")}>
        <MockCheckbox />
      </div>
      <div className={as("col")}>no</div>
      <div className={as("col")}>등록일</div>
      <div className={as("col")}>거래처</div>
      <div className={as("col")}>브랜드</div>
      <div className={as("col")}>배송 유형</div>
      <div className={as("col")}>
        특단가
        <br />
        자동저장
      </div>
      <div className={as("col")}>
        할인/할증
        <br />
        등급
      </div>
      <div className={as("col")}>총 매출</div>
      <div className={as("col")}>거래내역</div>
      <div className={as("col")}>거래상태</div>
      <div className={as("col")}>최종변경일</div>
    </div>
  );
};

const AccountListRow = ({
  idx,
  date,
  name,
  delivery,
  totalAmount,
}: AccountItem) => {
  const [now, setNow] = useState(dayjs());
  useEffect(() => {
    setNow(dayjs());
  }, []);

  return (
    <div className={as("tableRow")}>
      <div className={as("col")}>
        <MockCheckbox />
      </div>
      <div className={as("col")}>{idx}</div>
      <div className={as("col")}>{date}</div>
      <div className={as("col")}>{name}</div>
      <div className={as("col")}>{"기본"}</div>
      <div className={as("col")}>{delivery}</div>
      <div className={as("col")}>{"사용 해제"}</div>
      <div className={as("col")}>{"없음"}</div>
      <div className={as("col")}>{totalAmount}</div>
      <div className={as("col")} style={{ textDecoration: "underline" }}>
        {"보기"}
      </div>
      <div className={as("col")}>{"거래중"}</div>
      <div className={as("col")}>{now.format("YYYY-MM-DD")}</div>
    </div>
  );
};

export type AccountListTableProps = {
  accountList: readonly AccountItem[];
};
const AccountListTable = ({ accountList }: AccountListTableProps) => {
  return (
    <>
      <div className={as("overlap")}>
        {`전체 ${accountList.length} 개`}
        <Image src={accountListTotalDummy} alt="" width={200} />
      </div>

      <div className={as("accountListTable")}>
        <AccountListTableHeader />
        <div className={as("rows")}>
          {accountList.map((data) => {
            return <AccountListRow {...data} key={data.idx} />;
          })}
        </div>
      </div>
    </>
  );
};

export type AcocuntListPageProps = {
  brand: Brand;
};
export const AccountListPage = ({ brand }: AcocuntListPageProps) => {
  return (
    <div className={as("accountListPage")}>
      <Image src={accountListDummy} alt="" width={1200} style={{ zIndex: 2 }} />
      <CSR>
        <div>
          <AccountListTable accountList={brand.account} />
        </div>
      </CSR>
    </div>
  );
};

export type BrandListPageProps = {
  브랜드: Brand[];
  sceneAlias: string;
};

const BrandListPage = ({ 브랜드, sceneAlias }: BrandListPageProps) => {
  return (
    <div className={as("brandListPage")}>
      <Image src={brandList} alt="" width={1200} />
      <div
        style={{
          fontSize: "13.5px",
          background: "white",
          position: "absolute",
          left: "245px",
          top: "220px",
          paddingRight: "16px",
        }}
      >
        전체 {브랜드.length}건
      </div>
      <div className={as("brandListTableContent")}>
        {브랜드.map((brand, idx) => {
          return (
            <div key={brand.name + idx} className={as("row")}>
              <span>{브랜드.length - idx}</span>
              <span>{brand.name}</span>
              <div
                className={as(
                  idx === 1 &&
                    sceneAlias === "브랜드상품:Desktop:브랜드목록: 상품 수 점멸"
                    ? "clickMe"
                    : ""
                )}
                style={{
                  width: "100%",
                  height: "100%",
                  display: "grid",
                  placeContent: "center",
                }}
              >
                {brand.product.length}
              </div>
              <div
                className={as(
                  idx === 1 &&
                    sceneAlias ===
                      "브랜드상품:Desktop:브랜드목록: 거래처 수 점멸"
                    ? "clickMe"
                    : ""
                )}
                style={{
                  width: "100%",
                  height: "100%",
                  display: "grid",
                  placeContent: "center",
                }}
              >
                {brand.account.length}
              </div>
              <span>{brand.registerDate}</span>
              <span>
                <span style={{ textDecoration: "underline" }}>수정</span>
                {" / "}
                <span style={{ textDecoration: "underline" }}>삭제</span>
              </span>
              <span style={{ display: "flex", gap: "4px" }}>
                {브랜드.length - idx !== 1 && (
                  <span
                    style={{
                      display: "grid",
                      placeContent: "center",
                      width: "30px",
                      height: "30px",
                      border: "solid 1px #666",
                      color: "#666",
                      background: "#fafafa",
                    }}
                  >
                    ▼
                  </span>
                )}
                {idx !== 0 && (
                  <span
                    style={{
                      display: "grid",
                      placeContent: "center",
                      width: "30px",
                      height: "30px",
                      border: "solid 1px #666",
                      color: "#666",
                      background: "#fafafa",
                    }}
                  >
                    ▲
                  </span>
                )}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// type BrandAccountListPageProps = {
//   브랜드: Brand;
// };

// const BrandAccountListPage = ({ 브랜드 }: BrandAccountListPageProps) => {
//   return (
//     <div className={as("brandAccountListPage")}>
//       <div className={as("nameCover")}>{브랜드.name}</div>
//       <Image src={brandAccountListTopDummy} alt="" width={1200} />
//       <div
//         style={{
//           fontSize: "13px",
//           background: "white",
//           position: "absolute",
//           left: "428px",
//           top: "311.5px",
//           paddingRight: "16px",
//         }}
//       >
//         전체 {브랜드.account.length}건
//       </div>
//       <div className={as("tableRow")}>
//         <div className={as("rowHead")}>거래처목록</div>
//         <div className={as("cell")}>
//           <Image src={brandAccountListInnerTopDummy} alt={""} width={900} />
//           <div className={as("content")}>
//             {브랜드.account.map((account, idx) => (
//               <div
//                 key={account.idx}
//                 className={as("account")}
//                 style={{
//                   animationDelay: `${idx * 0.2}s`,
//                 }}
//               >
//                 <span>{account.idx}</span>
//                 <span>{account.name}</span>
//                 <span>{account.phone}</span>
//                 <span>{account.manager.name}</span>
//                 <span>{account.manager.phone}</span>
//                 <span>{account.date}</span>
//                 <span style={{ textDecoration: "underline" }}>삭제</span>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export const MockMarketbomDesktop = (props: Actor.ActorProps<"Desktop">) =>
  useMemo(() => {
    return (
      <>
        <div
          className={as(`mockDesktop ${props.screen}`)}
          style={Actor.parseOrder(props.location, props.zoom, props.duration)}
        >
          <SideBar {...props} />
          <div className={as("content")}>
            <Header />
            <div className={as("nested")}>
              {match(props.screen)
                .with("대시보드", () => (
                  <div className={as("innerContent")}>
                    <MockDashBoard />
                  </div>
                ))
                .with("주문목록", () => (
                  <div className={as("innerContent")}>
                    <OrderList {...props} key={props.screen} />
                  </div>
                ))
                // .with("공지사항목록", () => <NotificationList />)
                // .with("공지사항추가", () => <NotificationAdd />)
                .with("브랜드상품관리", () =>
                  pipe(
                    props.sceneContext.브랜드,
                    O.match(
                      (브랜드) => (
                        <BrandProductListPage
                          brandName={브랜드[1].name}
                          productList={브랜드[1].product}
                        />
                      ),
                      () => <></>
                    )
                  )
                )

                .with("브랜드목록", () =>
                  pipe(
                    props.sceneContext.브랜드,
                    O.match(
                      (브랜드) => (
                        <BrandListPage
                          브랜드={브랜드}
                          sceneAlias={props.sceneAlias}
                          key={브랜드[0].name}
                        />
                      ),
                      () => <></>
                    )
                  )
                )
                // .with("브랜드거래처목록", () =>
                //   pipe(
                //     props.sceneContext.브랜드,
                //     O.match(
                //       (브랜드) => <BrandAccountListPage 브랜드={브랜드[1]} />,
                //       () => <></>
                //     )
                //   )
                // )
                .exhaustive()}
            </div>
          </div>
          <div className={as("shiny")} />
          <div className={as("neck")} />
        </div>
      </>
    );
  }, [props]);

import { 링크 } from "./데이터/링크";
import { useTracker } from "./util/Tracker";
import { pipe, A } from "@mobily/ts-belt";

import { BoldableTextMultiline } from "./BoldableText";

import { type 고객사_리뷰, 리뷰_목록_모바일 } from "./데이터/고객사리뷰";
import styles from "./랜딩_섹션2_Mobile3.module.css";
import youtubeMini from "/public/assets/pc/images/pic/youtubemini.png";
import Image from "next/image";
import { asx } from "./util/applyStyle";
import { CustomerCategoryCTX } from "./util/CustomerCategoryCTX";
import { useContext } from "react";
const as = asx(styles);

const 리뷰 = ({ 리뷰 }: { 리뷰: 고객사_리뷰 }) => {
  return (
    <div className={as("review")}>
      <BoldableTextMultiline
        list={리뷰.text}
        align={"left"}
        style={{ width: "100%" }}
      />
      <span
        style={{
          color: "#8D8D8D",
          fontSize: "16px",
          lineHeight: "140%",
        }}
      >
        - {리뷰.name}
      </span>
    </div>
  );
};

const 리뷰목록 = () => {
  const { customerCategory: currentCustomerCategory } =
    useContext(CustomerCategoryCTX);

  return (
    <div
      className={as("reviewList")}
      style={{
        display: "inline-flex",
        width: "calc(100% - 40px)",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      {pipe(
        리뷰_목록_모바일.filter((x) =>
          x.customerCategory.includes(currentCustomerCategory)
        ),
        A.map((고객사_리뷰_) => (
          <리뷰 리뷰={고객사_리뷰_} key={고객사_리뷰_.name} />
        ))
      )}
    </div>
  );
};

export const 랜딩_섹션2_Mobile = () => {
  const tracker = useTracker();

  return (
    <div
      className="section section2"
      style={{ paddingBottom: "48px", backgroundColor: "#ECE9F1" }}
    >
      <dl>
        <dt style={{ marginBottom: "30px" }}>
          <div
            style={{
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "34px",
              letterSpacing: "-0.9px",
            }}
          >
            마켓봄 도입으로 업무 효율을 높인
          </div>
          <div
            style={{
              marginTop: "-4px",
              fontSize: "26px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "40px",
              letterSpacing: "-1.3px",
            }}
          >
            고객사 성공 사례
          </div>
        </dt>
      </dl>
      <iframe
        src={`https://www.youtube.com/embed/${링크.랜딩_영상}`}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          border: 0,
          width: "calc(100% - 40px)",
          aspectRatio: "760/428",
          borderRadius: "4.72px",
          boxShadow: "0px 0px 9.43953px 0px rgba(0, 0, 0, 0.15)",
        }}
        title={"마켓봄 성공 사례 인터뷰"}
        onClick={() => {
          tracker.trackingClick({
            depth1: "HOME",
            depth2: "마켓봄 소개 영상",
          });
        }}
      />
      <div
        style={{
          paddingLeft: "24px",
          marginTop: "10px",
          marginBottom: "28px",
          width: "100%",
          display: "flex",
          gap: "4px",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Image src={youtubeMini} alt={""} width={26} />
        <span
          style={{
            fontSize: "14px",
            letterSpacing: "-0.7px",
            fontWeight: "500",
            color: "#333",
          }}
        >
          한누리푸드 유통사 인터뷰
        </span>
      </div>
      <리뷰목록 />
    </div>
  );
};

import Image, { type StaticImageData } from "next/image";
import { asx } from "../../util/applyStyle";
import * as Actor from "../Actors";
import styles from "./MockMarketbom.module.css";
import logoSymbol from "/public/assets/pc/images/logo/logo-symbol.svg";
import notificationIcon from "/public/assets/pc/images/pic/mock_notification.svg";
import orderMenu from "/public/assets/pc/images/pic/mock_mobile_order.svg";
import orderDummy from "/public/assets/pc/images/pic/mock_mobile_dummy_order.svg";
import callDummy from "/public/assets/pc/images/pic/mock_dummy_call.svg";
import accountDummy from "/public/assets/pc/images/pic/mock_dummy_mobile_account.svg";
import gridMenuDummy from "/public/assets/pc/images/pic/mock_dummy_grid_menu.svg";
import miscDummy from "/public/assets/pc/images/pic/mock_dummy_mobile_misc.png";

import chevronLeft from "/public/assets/pc/images/icon/chevron_left.svg";
import searchAreaDummy from "/public/assets/pc/images/pic/mock_dummy_search_area.png";
import productDummy1Image from "/public/assets/pc/images/pic/mock_dummy_product1.png";
import productDummy2Image from "/public/assets/pc/images/pic/mock_dummy_product2.png";
import productDummy3Image from "/public/assets/pc/images/pic/mock_dummy_product3.png";

import filledStar from "/public/assets/pc/images/pic/mock_star_filled.svg";
import strokeStar from "/public/assets/pc/images/pic/mock_star_stroke.svg";

import type { SceneContext } from "../Scene";
import dayjs from "dayjs";
import { useMemo } from "react";

const as = asx(styles);

const NotificationModal = ({ show }: { show: boolean }) => {
  return (
    <div className={as(`scrim ${show ? "show" : "hide"}`)}>
      <div className={as("modal")}>
        <div className={as("title")}>{"공지사항"}</div>
        <div className={as("modalcontent")}>
          <div className={as("modalheader")}>주문마감시간 연장의 건</div>
          {"10/1부터 주문마감시간을 오후 9시로 연장합니다."}
        </div>
        <div className={as("modalfooter")}></div>
      </div>
    </div>
  );
};

const HomeScreen = ({ screen, sceneAlias }: Actor.ActorProps<"Mobile">) => {
  return (
    <div
      className={as(
        `screen 메인홈 ${screen === "메인홈" ? "visible" : "hide"}`
      )}
    >
      <NotificationModal
        show={sceneAlias === "공지:Mobile:메인홈: 모달 보여주기"}
      />
      <div className={as("header")}>
        <Image src={logoSymbol} alt={"홈"} width={24} height={20} />
        <span className={as("title")}>△△유통</span>
        <Image src={notificationIcon} alt={"홈"} width={22} height={19} />
      </div>
      <div
        className={as(
          `nofiticationLabel ${
            sceneAlias === "공지:Mobile:메인홈: 공지사항 보여주기"
              ? "show"
              : "hide"
          }`
        )}
      >
        {"10/1부터 주문마감시간을 오후 9시로 연장합니다."}
      </div>
      <div
        className={as(
          `nofiticationLabelNip ${
            sceneAlias === "공지:Mobile:메인홈: 공지사항 보여주기"
              ? "show"
              : "hide"
          }`
        )}
      />

      <div className={as("content")}>
        <div className={as("storeRow")}>
          <span className={as("storeName")}>OO식당 판교점</span>
          <span>{"유통사 변경 >"}</span>
        </div>
        <div
          className={as(
            sceneAlias === "유통사3:Mobile:메인홈: 직배송 버튼 클릭"
              ? "clickMe"
              : ""
          )}
        >
          <Image
            src={orderMenu}
            alt=""
            width={324}
            height={89}
            style={{ marginLeft: -2 }}
          />
        </div>
        <Image
          src={orderDummy}
          alt=""
          width={324}
          height={89}
          style={{ marginLeft: -2, marginBottom: 20 }}
        />
        <Image
          src={callDummy}
          alt=""
          width={324}
          height={54}
          style={{ marginLeft: -2 }}
        />
        <Image src={accountDummy} alt={""} style={{ marginBottom: "30px" }} />
        <Image src={gridMenuDummy} alt={""} />
        <Image src={miscDummy} alt={""} width={324} />;
      </div>
    </div>
  );
};

type ProductItemProps = {
  image: StaticImageData;
  favorited: boolean;
  name: string;
  unit: string;
  origin: string;
  price: string;
  amount: number;
  interactable?: {
    updateSceneContext: (newSceneState: SceneContext) => void;
    proceedScene: () => void;
    sceneContext: SceneContext;
    sceneAlias: string;
  };
};
const ProductItem = ({
  name,
  image,
  favorited,
  price,
  unit,
  origin,
  amount,
  interactable,
}: ProductItemProps) => {
  return (
    <div className={as("restList")}>
      <Image src={favorited ? filledStar : strokeStar} alt="" />
      <div className={as("inner")}>
        <div className={as("upperLayer")}>
          <Image src={image} alt={""} quality={100} />
          <div className={as("desc")}>
            <div className={as("name")}>{name}</div>
            <div className={as("descEtc")}>
              <div className={as("misc")}>
                <span>{unit}</span>
                <span>{origin}</span>
              </div>
              <div className={as("price")}>{price}</div>
            </div>
          </div>
        </div>
        <div className={as("lowerLayer")}>
          <div className={as("manipulate")}>
            <button
              className={as(
                `${interactable?.proceedScene ? "clickMeBlink" : ""} btn`
              )}
              onClick={() => {
                interactable?.updateSceneContext &&
                  interactable?.updateSceneContext({
                    ...interactable?.sceneContext,
                    냉동감자_개수: Math.max(1, amount - 1),
                  });
              }}
            >
              -
            </button>
            <div className={as("amount")}>{amount}</div>
            <button
              className={as(
                `btn ${
                  interactable?.sceneAlias ===
                  "PLP 화면. 냉동 감자의 수량 조절과 담기 버튼 클릭"
                    ? "clickMeBlink"
                    : ""
                }`
              )}
              onClick={() => {
                interactable?.updateSceneContext({
                  ...interactable?.sceneContext,
                  냉동감자_개수: Math.min(10, amount + 1),
                });
              }}
            >
              +
            </button>
          </div>
          <button
            className={as(`${interactable?.proceedScene ? "clickMe" : ""} btn`)}
            onClick={() => {
              interactable?.proceedScene();
            }}
          >
            {"담기"}
          </button>
        </div>
      </div>
    </div>
  );
};

const OrderScreen = ({
  screen,
  sceneContext,
  proceedScene,
  updateSceneContext,
  sceneAlias,
}: Actor.ActorProps<"Mobile">) => {
  return (
    <div className={as(`screen PLP ${screen === "PLP" ? "visible" : "hide"}`)}>
      <div className={as("header")}>
        <Image src={chevronLeft} alt={"홈"} />
        <span className={as("title")}>직배송 주문</span>
        <span style={{ width: 30 }} />
      </div>
      <div className={as("content")}>
        <div className={as("searchArea")}>
          <Image src={searchAreaDummy} alt="" width={320} />
        </div>
        <div className={as("productList")}>
          <div className={as("summary")}>상품목록(21)</div>
          <ProductItem
            image={productDummy1Image}
            name={"맥케인 케이준 후라이 감자튀김(냉동감자) 2kg"}
            unit={"2kg / EA / 2kg"}
            origin="미국"
            price={"23,000 원"}
            amount={sceneContext.냉동감자_개수}
            interactable={{
              updateSceneContext,
              proceedScene,
              sceneContext,
              sceneAlias,
            }}
            favorited
          />
          <ProductItem
            image={productDummy2Image}
            name={"코다노 피자치즈 DMC1 2.5kg 냉장(코이5:골드4)"}
            unit={"2.5kg x 6EA / 6EA / 15kg"}
            origin="외국산"
            price={"21,000 원"}
            amount={1}
            favorited
          />
          <ProductItem
            image={productDummy3Image}
            name={"통조림(캔,햄,백설,스팸)1.81kg"}
            unit={"1.81kg / EA"}
            origin="외국산"
            price={"18,000 원"}
            amount={1}
            favorited={false}
          />
          <ProductItem
            image={productDummy2Image}
            name={"코다노 피자치즈 DMC1 2.5kg 냉장(코이5:골드4)"}
            unit={"2.5kg x 6EA / 6EA / 15kg"}
            origin="외국산"
            price={"21,000 원"}
            amount={1}
            favorited={false}
          />
        </div>
      </div>
    </div>
  );
};

export const MockMarketbomMobile = (props: Actor.ActorProps<"Mobile">) =>
  useMemo(() => {
    return (
      <>
        <div
          className={as("mockMobile")}
          style={Actor.parseOrder(props.location, props.zoom, props.duration)}
        >
          <div className={as("powerButton")} />
          <div className={as("volume up")} />
          <div className={as("volume down")} />
          <div className={as("bezel")}>
            <time className={as("notibar")} suppressHydrationWarning>
              {dayjs().format("HH:mm")}
              <span>
                <div className={as("wifi")} />
                <div className={as("battery")}>
                  <div className={as("body")}></div>
                  <div className={as("tip")}></div>
                </div>
              </span>
            </time>
            <div className={as("innerPage")}>
              <HomeScreen {...props} />
              <OrderScreen {...props} />
            </div>
          </div>
        </div>
      </>
    );
  }, [props]);

import Link from "next/link";
import 섹션4_이미지1 from "/public/assets/pc/images/pic/img_section4_1_3.png";
import 섹션4_이미지2 from "/public/assets/pc/images/pic/img_section4_2_3.png";
import 더보기_화살표_아이콘 from "/public/assets/pc/images/icon/icon_right3.webp";
import { 무료체험기간 } from "./데이터/무료체험기간";
import { 링크 } from "./데이터/링크";
import { useTracker } from "./util/Tracker";
import { useContext } from "react";
import { GrowthBookCTX, getValue } from "./util/GrowthBookCTX";
import { pipe } from "@mobily/ts-belt";
import Image from "next/image";

export const 랜딩_섹션4_Desktop = () => {
  const tracker = useTracker();
  const featureFlag = useContext(GrowthBookCTX);
  const simplifiedRegister = pipe(featureFlag, getValue("simplified-register"));

  return (
    <div className={"section section4"} style={{ backgroundColor: "#533b85" }}>
      <div className="inner">
        <p className="desc" style={{ marginBottom: "6px" }}>
          가입비, 설치비, 세팅비가 모두 무료!
        </p>
        <h3 style={{ marginBottom: "85px" }}>업종별 마켓봄 서비스</h3>
        <div className="col-wrap">
          <div className="col">
            <div className="row">
              <span className="num open-sans" style={{ color: "#9F92BB" }}>
                01
              </span>
              <dl
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <dt style={{ paddingBottom: "24px" }}>
                  유통사
                  <br />
                  프랜차이즈 본사, 물류사 등
                </dt>
                <Image
                  src={섹션4_이미지1}
                  alt=""
                  quality={100}
                  width={305}
                  style={{ marginBottom: "16px" }}
                />
                <dd>{`결제없이 ${무료체험기간.마켓봄} 무료이용`}</dd>
              </dl>
              <div className="btn-wrap">
                <Link
                  href={
                    simplifiedRegister
                      ? 링크.마켓봄_간편가입_실험_링크_PC
                      : 링크.마켓봄_간편가입_PC
                  }
                  className="btn-start"
                  onClick={() =>
                    tracker.trackingClick({
                      depth1: "INFO",
                      depth2: "회원가입",
                      depth3: "마켓봄",
                      indicator: "회원가입",
                    })
                  }
                >
                  시작하기
                </Link>
              </div>
            </div>
            <div className="row">
              <p>
                세상의 모든 수발주를 쉽고 간편하게
                <br />
                실시간으로 처리
              </p>
              <div className="btn-wrap">
                <Link
                  href={링크.마켓봄_자세히보기}
                  className="btn-detail"
                  style={{
                    background: `url(${더보기_화살표_아이콘.src}) no-repeat right 1px center`,
                  }}
                  onClick={() =>
                    tracker.trackingClick({
                      depth1: "INFO",
                      depth2: "마켓봄 자세히보기",
                    })
                  }
                >
                  <span className="fc1">마켓봄</span> 자세히 보기
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="row">
              <span className="num open-sans" style={{ color: "#9F92BB" }}>
                02
              </span>
              <dl
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <dt style={{ paddingBottom: "24px" }}>
                  중도매인
                  <br />
                  상회, 중상업자 등
                </dt>
                <Image
                  src={섹션4_이미지2}
                  alt=""
                  quality={100}
                  style={{ marginBottom: "16px" }}
                  height={150}
                />
                <dd>결제없이 {무료체험기간.마켓봄도매} 무료이용</dd>
              </dl>
              <div className="btn-wrap">
                <Link
                  href={링크.마켓봄도매_회원가입}
                  className="btn-start"
                  onClick={() =>
                    tracker.trackingClick({
                      depth1: "INFO",
                      depth2: "회원가입",
                      depth3: "마켓봄 도매",
                    })
                  }
                >
                  시작하기
                </Link>
              </div>
            </div>
            <div className="row">
              <p>
                가장 저렴하고 쉽고 간편한
                <br />
                판매 관리 프로그램
              </p>
              <div className="btn-wrap">
                <Link
                  href={링크.마켓봄도매_자세히보기}
                  className="btn-detail"
                  style={{
                    background: `url(${더보기_화살표_아이콘.src}) no-repeat right 1px center`,
                  }}
                  onClick={() =>
                    tracker.trackingClick({
                      depth1: "INFO",
                      depth2: "마켓봄 도매 자세히보기",
                    })
                  }
                >
                  <span className="fc1">마켓봄 도매</span> 자세히 보기
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

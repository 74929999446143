// import 랜딩_Mobile from "../components/랜딩_Mobile";
import * as 장치 from "../components/장치";
import type { GetServerSideProps } from "next";

import 랜딩_Desktop2 from "../components/랜딩_Desktop2";
import 랜딩_Desktop3 from "../components/랜딩_Desktop3";

import { pipe } from "@mobily/ts-belt";
import { getValue, GrowthBookCTX } from "../components/util/GrowthBookCTX";
import { loadApplicationPageProps, type ApplicationPageProps } from "./_app";

import { LayoutSplitter } from "../layouts/LayoutSplitter";

import { useContext } from "react";
import { match, P } from "ts-pattern";
import 랜딩_Mobile2 from "../components/랜딩_Mobile2";
import 랜딩_Mobile3 from "../components/랜딩_Mobile3";

type 랜딩Props = {
  장치: 장치.t;
} & ApplicationPageProps;

const 랜딩 = ({ 장치 }: 랜딩Props) => {
  const featureValue = useContext(GrowthBookCTX);
  const simplifiedRegister = pipe(
    featureValue,
    getValue("simplified-register")
  );
  const miniatureLanding = pipe(featureValue, getValue("miniature"));
  return (
    <LayoutSplitter
      장치={장치}
      pc={match([simplifiedRegister, miniatureLanding])
        .with([P._, false], () => <랜딩_Desktop2 />)
        .with([P._, true], () => <랜딩_Desktop3 />)
        .exhaustive()}
      mobile={match(miniatureLanding)
        .with(false, () => <랜딩_Mobile2 />)
        .with(true, () => <랜딩_Mobile3 />)
        .exhaustive()}
    />
  );
};

export default 랜딩;

export const getServerSideProps: GetServerSideProps<랜딩Props> = async (
  ctx
) => {
  const applicationPageProps = await loadApplicationPageProps(ctx);

  return {
    props: {
      장치: 장치.fromContext(ctx),
      ...applicationPageProps,
    },
  };
};

import { A, pipe } from "@mobily/ts-belt";

export type BrandProductList = {
  isBranded: boolean;
  name: string;
  code: string;
  category: string;
  unit: "KG";
  origin: "국내산" | "네덜란드" | "브라질";
};

export const genRandom = () => {
  return pipe(
    brandProductList,
    A.shuffle,
    A.slice(
      0,
      Math.max(4, Math.floor(Math.random() * brandProductList.length - 4))
    ),
    A.map((prod) => ({ ...prod, isBranded: false }))
  );
};

export const brandProductList: BrandProductList[] = [
  {
    isBranded: true,
    name: "신선한 사과",
    code: "SA001",
    category: "과일",
    unit: "KG",
    origin: "국내산",
  },
  {
    isBranded: false,
    name: "프레시 케일",
    code: "PK002",
    category: "채소",
    unit: "KG",
    origin: "네덜란드",
  },
  {
    isBranded: true,
    name: "최고급 소고기",
    code: "BSG003",
    category: "육류",
    unit: "KG",
    origin: "브라질",
  },
  {
    isBranded: false,
    name: "달콤한 오렌지",
    code: "SO004",
    category: "과일",
    unit: "KG",
    origin: "국내산",
  },
  {
    isBranded: true,
    name: "건강한 브로콜리",
    code: "HB005",
    category: "채소",
    unit: "KG",
    origin: "네덜란드",
  },
  {
    isBranded: false,
    name: "부드러운 닭고기",
    code: "SC006",
    category: "육류",
    unit: "KG",
    origin: "국내산",
  },
  {
    isBranded: true,
    name: "물컹한 수박",
    code: "CW007",
    category: "과일",
    unit: "KG",
    origin: "국내산",
  },
  {
    isBranded: false,
    name: "신선한 토마토",
    code: "FT008",
    category: "채소",
    unit: "KG",
    origin: "네덜란드",
  },
  {
    isBranded: true,
    name: "맛있는 돼지고기",
    code: "DP009",
    category: "육류",
    unit: "KG",
    origin: "브라질",
  },
  {
    isBranded: false,
    name: "달콤한 배",
    code: "SP010",
    category: "과일",
    unit: "KG",
    origin: "국내산",
  },
  {
    isBranded: true,
    name: "건강한 시금치",
    code: "HS011",
    category: "채소",
    unit: "KG",
    origin: "네덜란드",
  },
  {
    isBranded: false,
    name: "최고급 양고기",
    code: "PL012",
    category: "육류",
    unit: "KG",
    origin: "브라질",
  },
  {
    isBranded: true,
    name: "신선한 복숭아",
    code: "FP013",
    category: "과일",
    unit: "KG",
    origin: "국내산",
  },
  {
    isBranded: false,
    name: "맛있는 당근",
    code: "DC014",
    category: "채소",
    unit: "KG",
    origin: "네덜란드",
  },
  {
    isBranded: true,
    name: "부드러운 오리고기",
    code: "SD015",
    category: "육류",
    unit: "KG",
    origin: "브라질",
  },
  {
    isBranded: false,
    name: "달콤한 복숭아",
    code: "SP016",
    category: "과일",
    unit: "KG",
    origin: "국내산",
  },
  {
    isBranded: true,
    name: "건강한 브로콜리",
    code: "HB017",
    category: "채소",
    unit: "KG",
    origin: "네덜란드",
  },
  {
    isBranded: false,
    name: "신선한 포도",
    code: "SG018",
    category: "과일",
    unit: "KG",
    origin: "국내산",
  },
  {
    isBranded: true,
    name: "최고급 소고기",
    code: "BSG019",
    category: "육류",
    unit: "KG",
    origin: "브라질",
  },
  {
    isBranded: false,
    name: "맛있는 당근",
    code: "DC020",
    category: "채소",
    unit: "KG",
    origin: "네덜란드",
  },
  {
    isBranded: true,
    name: "신선한 사과",
    code: "SA021",
    category: "과일",
    unit: "KG",
    origin: "국내산",
  },
  {
    isBranded: false,
    name: "프레시 케일",
    code: "PK022",
    category: "채소",
    unit: "KG",
    origin: "네덜란드",
  },
  {
    isBranded: true,
    name: "최고급 소고기",
    code: "BSG023",
    category: "육류",
    unit: "KG",
    origin: "브라질",
  },
  {
    isBranded: false,
    name: "맛있는 오렌지",
    code: "SO024",
    category: "과일",
    unit: "KG",
    origin: "국내산",
  },
];

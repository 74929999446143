import { DemoStage, DemostageSkeleton } from "./mockui/Scene/DemoStage";
import { asx } from "./util/applyStyle";
import styles from "./체험데모_Desktop.module.css";
import { type SceneKind, type SceneScript } from "./mockui/Scene";
import { BoldableTextMultiline } from "./BoldableText";
import { type BoldableText } from "./BoldableText";
import Link from "next/link";
import { 링크 } from "./데이터/링크";
import { useTracker } from "./util/Tracker";

const as = asx(styles);

export type 체험데모_DesktopProps<T extends SceneKind> = {
  play: SceneScript<T>;
  label: AsideProps<T>;
};

type AsideProps<T extends SceneKind> = {
  title: BoldableText[][];
  content: BoldableText[][];
  position: [number, number];
  link: T;
};
export const Info = <T extends SceneKind>({
  title,
  content,
  position,
  link,
}: AsideProps<T>) => {
  const tracker = useTracker();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        transform: `translateX(${position[0]}px) translateY(${position[1]}px)`,
      }}
    >
      <BoldableTextMultiline
        list={title}
        align={"left"}
        size={32}
        style={{ marginBottom: "6px" }}
      />
      <BoldableTextMultiline
        list={content}
        align={"left"}
        size={18}
        style={{
          marginBottom: "8px",
        }}
      />
      <Link
        style={{
          borderRadius: "100px",
          background: "#FE6B37",
          width: "160px",
          height: "38px",
          color: "#FFF",
          textAlign: "center",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "140%",
          letterSpacing: "-0.48px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          tracker.trackingClick({
            depth1: "HOME",
            depth2: "사용해보기",
            depth3: link,
          });
        }}
        href={링크.마켓봄_간편가입_실험_링크_PC}
      >
        직접 사용해보기
      </Link>
    </div>
  );
};

export const 체험데모_Desktop = <T extends SceneKind>({
  play,
  label,
}: 체험데모_DesktopProps<T>) => {
  return (
    <div className={as("scrollWrapper")}>
      <div className={as("DemoSection")}>
        <DemoStage script={play} />
        <Info {...label} />
      </div>
    </div>
  );
};

export const 체험데모_DesktopSkeleton = ({
  label,
}: {
  label: AsideProps<SceneKind>;
}) => {
  return (
    <div className={as("scrollWrapper")}>
      <div className={as("DemoSection")}>
        <DemostageSkeleton />
        <Info {...label} />
      </div>
    </div>
  );
};

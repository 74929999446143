import { 푸터_Mobile } from "./푸터_Mobile";
import { 마켓봄_장점_Mobile } from "./마켓봄_장점_Mobile3";
import { 마켓봄_업데이트_소식_Mobile } from "./마켓봄_업데이트_소식_Mobile3";
import { 랜딩_섹션6_Mobile } from "./랜딩_섹션6_Mobile3";
import { CSR } from "./CSR";
import { 랜딩_섹션1_Mobile } from "./랜딩_섹션1_Mobile3";
import { 랜딩_섹션2_Mobile } from "./랜딩_섹션2_Mobile3";
import { 랜딩_섹션4_Mobile } from "./랜딩_섹션4_Mobile3";
import { 랜딩_섹션9_Mobile } from "./랜딩_섹션9_Mobile3";
import { 랜딩_섹션5_Mobile } from "./랜딩_섹션5_Mobile3";
import { CTA_회원가입 } from "./CTA_회원가입_Mobile3";
import { 헤더_MobileAB } from "./헤더_MobileAB";
import { useState } from "react";
import {
  type CustomerCategory,
  CustomerCategoryCTX,
} from "./util/CustomerCategoryCTX";
import 랜딩_고객사_목록_Mobile from "./랜딩_고객사_목록_Mobile";

const 랜딩_Mobile = () => {
  const [customerCategory, setCustomerCategory] =
    useState<CustomerCategory>("유통사");

  return (
    <CustomerCategoryCTX.Provider
      value={{ customerCategory, setCustomerCategory }}
    >
      <div id="wrap">
        <헤더_MobileAB />
        <div id="container" className="main">
          <랜딩_섹션1_Mobile />
          <랜딩_섹션2_Mobile />
          <CSR>
            <랜딩_섹션6_Mobile />
          </CSR>
          <랜딩_고객사_목록_Mobile />
          <마켓봄_장점_Mobile />
          <랜딩_섹션4_Mobile />
          <랜딩_섹션5_Mobile />

          <마켓봄_업데이트_소식_Mobile />
          <CSR>
            <랜딩_섹션9_Mobile />
          </CSR>
        </div>
        <푸터_Mobile />
        <CTA_회원가입 />
      </div>
    </CustomerCategoryCTX.Provider>
  );
};
export default 랜딩_Mobile;

import { pipe, D } from "@mobily/ts-belt";
import type {
  Actor,
  ActorLocation,
  DesktopScreen,
  Milliseconds,
  MobileScreen,
  SceneContext,
  Screen,
} from "./Scene";

import type { CSSProperties } from "react";
export type ActorProps<Actor_ extends Actor> = {
  location: ActorLocation;
  screen: Screen<Actor_>;
  sceneContext: SceneContext;
  sceneAlias:
    | `${string}:${"Mobile" | "Desktop"}:${
        | MobileScreen
        | DesktopScreen}: ${string}`
    | "";
  proceedScene: () => void;
  updateSceneContext: (ctx: SceneContext) => void;
  duration: Milliseconds;
  playFocused: boolean;
  zoom: number;
};

const parseLocation =
  (zoom: number) =>
    ([x, y]: ActorLocation): CSSProperties => ({
      transformOrigin: "center center",
      transform: `scale(${zoom}) translateX(calc((${x} - 50%) / ${zoom})) translateY(calc((${y} - 50%) / ${zoom}))`,
    });

export const parseOrder = (
  order: ActorLocation,
  zoom: number,
  duration: Milliseconds
): CSSProperties => {
  return pipe(
    order,
    parseLocation(zoom),
    D.set("transition", `all ${duration} cubic-bezier(0.2,0.8,0.2,1)`)
    // D.set("transition", `all ${duration} linear`)
    // D.set("transition", `all ${duration} cubic - bezier(0.15, 1, 0.15, 1)`)
  );
};

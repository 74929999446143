export type OrderListTable = {
  no: number;
  주문일시: string;
  배송요청일: string;
  주문번호: string;
  거래처: string;
  총_주문수량: number;
  결제수단: "신용카드" | "외상거래";
  주문금액: string;
  className?: string;
};
export const orderList: OrderListTable[] = [
  {
    no: 4,
    주문일시: "2023-08-20\n21:23:31",
    배송요청일: "2023-08-22",
    주문번호: "LDOBbmn102359789",
    거래처: "가맹점C",
    총_주문수량: 3,
    결제수단: "신용카드",
    주문금액: "98,350원",
  },
  {
    no: 3,
    주문일시: "2023-08-20\n15:48:24",
    배송요청일: "2023-08-22",
    주문번호: "BWLVbmn112449342",
    거래처: "직영점",
    총_주문수량: 12,
    결제수단: "외상거래",
    주문금액: "1,234,220원",
  },
  {
    no: 2,
    주문일시: "2023-08-20\n15:52:16",
    배송요청일: "2023-08-21",
    주문번호: "AOSIbmn197843456",
    거래처: "가맹점B",
    총_주문수량: 6,
    결제수단: "신용카드",
    주문금액: "190,100원",
  },
  {
    no: 1,
    주문일시: "2023-08-20\n15:48:24",
    배송요청일: "2023-08-21",
    주문번호: "AYDVbmn102749395",
    거래처: "가맹점A",
    총_주문수량: 5,
    결제수단: "신용카드",
    주문금액: "414,930원",
  },
];

import {
  useState,
  useEffect,
  useCallback,
  type PropsWithChildren,
  useRef,
  type ElementRef,
} from "react";
import type {
  ActorRole,
  DesktopScreen,
  DressRoomScene,
  MobileScreen,
  SceneContext,
  SceneKind,
  SceneScript,
} from "../Scene";
import styles from "./DemoStage.module.css";
import { asx } from "../../util/applyStyle";
import { MockMarketbomMobile } from "../mobile/MockMarketbom";
import { MockMarketbomDesktop } from "../desktop/MockMarketbom";

import { O, pipe } from "@mobily/ts-belt";
import type { AutoProceedScene, StaticScene } from "../Scene";
import { LabelActor } from "../LabelActor/LabelActor";
const as = asx(styles);
export type DemoStageProps<S extends SceneKind> = {
  script: SceneScript<S>;
  sceneNumber?: number;
};

export type ShowMainActorRoleProps = {
  mainActor: O.Option<ActorRole>;
};

export const ShowMainActorRole = ({ mainActor }: ShowMainActorRoleProps) => {
  return (
    <div
      className={as(`mainActorChip ${O.isSome(mainActor) ? "show" : "hide"}`)}
    >
      <div className={as("content")}>
        <div>
          <span className={"fc1 bold"}>{mainActor}</span>
          <span>에서 보는 화면</span>
        </div>
      </div>
    </div>
  );
};

export const DemostageSkeleton = () => {
  return <div className={as("StageWrapper")}></div>;
};

export const DemoStage = <S extends SceneKind>({
  script,
  // sceneNumber,
  children,
}: PropsWithChildren<DemoStageProps<S>>) => {
  const [sceneNumber, setSceneNumber] = useState(0);
  const [hoverPlay, setHoverPlay] = useState(false);
  const wrapperRef = useRef<ElementRef<"div">>(null);
  const currentScene:
    | AutoProceedScene<S, MobileScreen, DesktopScreen>
    | StaticScene<S, MobileScreen, DesktopScreen>
    | DressRoomScene = script.scenes[sceneNumber ?? 0] ?? script.scenes[0];
  const [sceneState, setSceneState] = useState<SceneContext>(
    script.initialSceneState
  );
  const lastScene = script.scenes.length - 1;

  useEffect(() => {
    setSceneNumber(0);
    setSceneState(script.initialSceneState);
  }, [script]);

  const proceedScene = useCallback(() => {
    setSceneNumber(sceneNumber + 1 > lastScene ? 0 : sceneNumber + 1);
  }, [lastScene, sceneNumber]);

  useEffect(() => {
    if (currentScene.autoProceed) {
      const timer = setTimeout(() => {
        proceedScene();
      }, currentScene.autoProceed.delay);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {};
  }, [currentScene, proceedScene]);
  const onHover = () => {
    setHoverPlay(true);
  };

  const onLeave = () => {
    setHoverPlay(false);
  };

  return (
    <div
      ref={wrapperRef}
      className={as("StageWrapper")}
      style={{
        transformOrigin: "top center",
        transition: "all 0.5s cubic-bezier(0.2,0.8,0.2,1)",
        willChange: "transform ",
      }}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      <div className={as(`DemoStage ${script.playName}`)}>
        {O.isSome(currentScene.label) ? (
          <LabelActor
            {...currentScene.label}
            align={currentScene.label.align}
          />
        ) : (
          <>{""}</>
        )}
        {pipe(
          currentScene.Mobile,
          O.match(
            (mobile) => (
              <MockMarketbomMobile
                playFocused={hoverPlay}
                location={mobile.location}
                screen={mobile.screen}
                proceedScene={proceedScene}
                sceneContext={sceneState}
                sceneAlias={currentScene.alias}
                updateSceneContext={(newSceneState: SceneContext) => {
                  setSceneState(newSceneState);
                }}
                duration={currentScene.duration}
                zoom={currentScene.zoom ?? 1}
              />
            ),
            () => <></>
          )
        )}
        {pipe(
          currentScene.Desktop,
          O.match(
            (desktop) => (
              <MockMarketbomDesktop
                playFocused={hoverPlay}
                location={desktop.location}
                screen={desktop.screen}
                proceedScene={proceedScene}
                sceneContext={sceneState}
                sceneAlias={currentScene.alias}
                updateSceneContext={(newSceneState: SceneContext) => {
                  setSceneState(newSceneState);
                }}
                duration={currentScene.duration}
                zoom={currentScene.zoom ?? 1}
              />
            ),
            () => <></>
          )
        )}
        {/* <ShowMainActorRole mainActor={currentScene.mainActor} /> */}
        {children}
      </div>
    </div>
  );
};

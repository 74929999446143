import { createContext, type Dispatch, type SetStateAction } from "react";

export const CUSTOMTER_CATEGORY = [
  "유통사",
  "프랜차이즈 본사",
  "중도매인",
] as const;
export type CustomerCategory = (typeof CUSTOMTER_CATEGORY)[number];

export type CustomerCategoryCTX = {
  customerCategory: CustomerCategory;
  setCustomerCategory: Dispatch<
    SetStateAction<"유통사" | "프랜차이즈 본사" | "중도매인">
  >;
};

export const CustomerCategoryCTX = createContext<CustomerCategoryCTX>({
  customerCategory: "유통사",
  setCustomerCategory: () => {},
});

import 섹션3이미지8 from "/public/assets/pc/images/pic/img_section3_8.webp";
import 섹션3이미지9 from "/public/assets/pc/images/pic/img_section3_9.webp";
import 섹션3이미지7 from "/public/assets/pc/images/pic/img_section3_7.webp";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import styles from "./랜딩_섹션3_Desktop3.module.css";
import { asx } from "./util/applyStyle";
import Link from "next/link";
import { useTracker } from "./util/Tracker";

const as = asx(styles);

export const 랜딩_섹션3_Desktop_Skeleton = forwardRef<HTMLDivElement>(
  function Renderer(_props, ref) {
    const tracker = useTracker();
    return (
      <div
        className={as("section section3")}
        style={{ paddingTop: "100px" }}
        ref={ref}
      >
        <div
          className={"inner " + as("content")}
          style={{ padding: "0px", display: "flex", flexDirection: "column" }}
        >
          <span
            style={{
              textAlign: "center",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "34px",
              letterSpacing: "-1.2px",
              marginBottom: "6px",
            }}
          >
            아직도 마켓봄 도입을 망설이고 계신가요?
          </span>
          <h3 style={{ marginBottom: "70px", color: "#333" }}>
            마켓봄, 이런 장점도 있어요!
          </h3>
          <div
            className={as("row type7 row-right")}
            style={{
              background: `url(${섹션3이미지7.src}) no-repeat right top`,
              paddingLeft: "660px",
            }}
          >
            <dl>
              <dt>
                실시간 확인이 어렵던
                <strong className="bold"> 매출/매입 조회</strong>
                <br />
                <span className="fc1">
                  마켓봄은
                  <strong className="bold"> 유통사 앱으로 즉시 확인</strong>
                </span>
              </dt>
              <dd>
                유통사 주문확인용
                <strong className="bold"> 모바일 앱 제공</strong>으로
                <br />
                실시간으로 매출과 매입의 상세내역까지
                <br />
                확인할 수 있습니다.
              </dd>
            </dl>
          </div>
          <div
            className={as("row type8")}
            style={{
              background: `url(${섹션3이미지8.src}) no-repeat left top`,
            }}
          >
            <dl>
              <dt>
                프로그램이 설치된 장소 외
                <strong className="bold"> 업무 불가능</strong>
                <br />
                <span className="fc1">
                  마켓봄은 설치 없이
                  <strong className="bold"> 언제 어디서나 접속</strong>
                </span>
              </dt>
              <dd>
                인터넷이 가능한 공간이면
                <br />
                언제 어디서든 업무가 가능합니다.
              </dd>
            </dl>
          </div>
          <div
            className={as("row type9 row-right")}
            style={{
              background: `url(${섹션3이미지9.src}) no-repeat right top`,
              paddingLeft: "660px",
            }}
          >
            <dl>
              <dt>
                복잡하고 오래 걸리는 <strong className="bold">초기 세팅</strong>
                <br />
                <span className="fc1">
                  마켓봄은
                  <strong className="bold"> 긴급 방문과 원격 세팅 지원</strong>
                </span>
              </dt>
              <dd>
                영업 사원이 방문하여 교육을 하고,
                <br />
                기존 전산 데이터를 이관하고,
                <br />
                원격으로도 초기 세팅을 지원해드립니다.
                <br />
                <strong className="bold">초기세팅 비용 모두 무료!</strong>
              </dd>
            </dl>
          </div>
          <Link
            href={"/features"}
            className={as("more")}
            onClick={() =>
              tracker.trackingClick({
                depth1: "HOME",
                depth2: "특장점 더보기",
              })
            }
          >
            더 알아보기
          </Link>
        </div>
      </div>
    );
  }
);

export const 랜딩_섹션3_Desktop = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [fireOnce, setFireOnce] = useState(false);
  const tracker = useTracker();

  useEffect(() => {
    const 특장점listener = () => {
      setIsVisible(window.scrollY > (ref.current?.offsetTop ?? 99999));
    };

    window.addEventListener("scroll", 특장점listener);
    return () => {
      window.removeEventListener("scroll", 특장점listener);
    };
  }, [ref, isVisible]);

  useEffect(() => {
    console.log(fireOnce, isVisible);
    if (!fireOnce && isVisible) {
      tracker.trackingScroll({
        depth1: "HOME",
        depth2: "특장점",
      });
      setFireOnce(true);
    }
  }, [isVisible, fireOnce, tracker]);

  return <랜딩_섹션3_Desktop_Skeleton ref={ref} />;
};

import { 체험데모_Desktop, 체험데모_DesktopSkeleton } from "./체험데모_Desktop";
import { 주문앱씬 } from "./mockui/Scene/주문앱";
import { 수발주취합씬 } from "./mockui/Scene/수발주취합";
import { 공지씬 } from "./mockui/Scene/공지";

import { CSR } from "./CSR";
import { highlightedText, normalText } from "./BoldableText";
import { 브랜드상품씬 } from "./mockui/Scene/브랜드상품";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useTracker } from "./util/Tracker";

const n = normalText;
const nn = (x: string) => ({ ...normalText(x), style: { color: "#8d8d8d" } });
const blk = (x: string) => ({ ...nn(x), style: { color: "inherit" } });
const h = highlightedText;

export const 랜딩_애니메이션_Desktop_Skeleton = forwardRef<HTMLDivElement>(
  function renderer(_props, ref) {
    return (
      <div
        ref={ref}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "1200px",
          padding: "85px 0px 120px 0px",
          margin: "0px auto",
          gap: "64px",
        }}
      >
        <dl
          style={{
            color: "#333",
            textAlign: "center",
            fontSize: "40px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "40px",
            letterSpacing: "-2px",
          }}
        >
          <div
            style={{
              fontSize: "24px",
              fontWeight: "normal",
              marginBottom: "6px",
            }}
          >
            프랜차이즈, 유통사 사장님들이
          </div>
          <div>마켓봄을 선택한 이유</div>
        </dl>
        <체험데모_Desktop
          play={주문앱씬}
          label={{
            title: [[n("쉽고 간편한")], [h("거래처 주문앱 "), n("제공")]],
            content: [
              [nn("거래처 주문용 모바일 앱 제공으로")],
              [blk("사장님들의 식자재 주문이 편해집니다.")],
            ],
            position: [300, 0],
            link: "주문앱",
          }}
        />

        <체험데모_Desktop
          play={수발주취합씬}
          label={{
            title: [[n("식자재 주문 즉시")], [h("실시간 수발주 취합")]],
            content: [
              [nn("거래처 주문 즉시 "), blk("수발주 내역을")],
              [blk("자동 취합하여 확인"), nn("할 수 있습니다.")],
            ],
            position: [-350, 0],
            link: "수발주취합",
          }}
        />

        <체험데모_Desktop
          play={공지씬}
          label={{
            title: [
              [n("가맹점 공지사항은")],
              [n("모바일 앱으로 "), h("한 번에 발송")],
            ],
            content: [
              [nn("본사 정책, 배송 단가 변경 등의 이슈사항을")],
              [nn("한꺼번에 공지하여 "), blk("가맹점 관리 효율이 높아집니다.")],
            ],
            position: [250, 0],
            link: "공지",
          }}
        />
        <CSR
          fallback={
            <체험데모_DesktopSkeleton
              label={{
                title: [[n("가맹점 그룹 관리로")], [h("맞춤 상품 노출")]],
                content: [
                  [nn("브랜드별 가맹점 관리에 최적화되어")],
                  [blk("맞춤 상품을 노출해 매출 증대"), nn("가 가능합니다.")],
                ],
                position: [-350, 0],
                link: "브랜드상품",
              }}
            />
          }
        >
          <체험데모_Desktop
            play={브랜드상품씬}
            label={{
              title: [[n("가맹점 그룹 관리로")], [h("맞춤 상품 노출")]],
              content: [
                [nn("브랜드별 가맹점 관리에 최적화되어")],
                [blk("맞춤 상품을 노출해 매출 증대"), nn("가 가능합니다.")],
              ],
              position: [-350, 0],
              link: "브랜드상품",
            }}
          />
        </CSR>
      </div>
    );
  }
);

export const 랜딩_애니메이션_Desktop = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [fireOnce, setFireOnce] = useState(false);
  const tracker = useTracker();

  useEffect(() => {
    const 사용해보기Listener = () => {
      setIsVisible(window.scrollY > (ref.current?.offsetTop ?? 99999));
    };

    window.addEventListener("scroll", 사용해보기Listener);
    return () => {
      window.removeEventListener("scroll", 사용해보기Listener);
    };
  }, [ref, isVisible]);

  useEffect(() => {
    if (!fireOnce && isVisible) {
      tracker.trackingScroll({
        depth1: "HOME",
        depth2: "사용해보기",
      });
      setFireOnce(true);
    }
  }, [isVisible, fireOnce, tracker]);

  return <랜딩_애니메이션_Desktop_Skeleton ref={ref} />;
};

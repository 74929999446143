import dayjs from "dayjs";
import { type AccountItem, brandNameGen, genAccountListN } from "./AccountList";
import { genRandom, type BrandProductList } from "./BrandProductList";

export type Brand = {
  name: string;
  product: readonly BrandProductList[];
  account: readonly AccountItem[];
  registerDate: string;
};

export const genBrand = (): Brand => ({
  name: brandNameGen(),
  product: genRandom(),
  account: genAccountListN(Math.random() * 5 + 1),
  registerDate: dayjs().format("YYYY-MM-DD"),
});

export const genBrandN = (
  amount: number,
  cur = 0,
  acc: Brand[] = []
): Brand[] => {
  if (cur >= amount) {
    return acc;
  }
  return genBrandN(amount, cur + 1, [...acc, genBrand()]);
};

import styles from "./CTA_회원가입_Mobile2.module.css";
import { 링크 } from "./데이터/링크";
import { useTracker } from "./util/Tracker";
import 전화_아이콘 from "/public/assets/mobile/images/icon/icon_call.svg";
import { asx } from "./util/applyStyle";
import Image from "next/image";
import { 무료체험기간 } from "./데이터/무료체험기간";

const as = asx(styles);
export const CTA_회원가입 = () => {
  const tracker = useTracker();

  return (
    <>
      <div className={styles["cta-spacer"]} />

      <div className={as("cta docked")}>
        <a
          href={링크.마켓봄_간편가입_실험_링크_모바일}
          className={as("btn btn-join touch content")}
          onClick={() => {
            tracker.trackingClick({
              depth1: "HOME",
              depth2: "회원가입",
              depth3: "마켓봄",
              indicator: "회원가입",
            });
          }}
        >
          <div className={as("overlay")} />
          <div className={as("signUpCatchPhrase")}>
            <span>{`${무료체험기간.마켓봄} 무료 체험`}</span>
            <span>{"5초 만에 시작하기"}</span>
            <span>{`${무료체험기간.마켓봄} 무료 체험`}</span>
          </div>
        </a>
        <a
          href={링크.전화}
          className={as("btn-cs-center touch")}
          onClick={() => {
            tracker.trackingClick({ depth1: "GNB", depth2: "전화하기" });
          }}
        >
          <Image
            src={전화_아이콘}
            alt=""
            style={{
              pointerEvents: "none",
            }}
          />
        </a>
      </div>
    </>
  );
};

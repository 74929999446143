import {
  boldText,
  type BoldableText,
  highlightedText,
  normalText,
} from "../BoldableText";
import type { CustomerCategory } from "../util/CustomerCategoryCTX";

export type 고객사_리뷰 = {
  text: BoldableText[][];
  name: string;
  customerCategory: readonly CustomerCategory[];
};

const b = boldText;
const h = highlightedText;
const n = normalText;

export const 리뷰_목록: 고객사_리뷰[] = [
  {
    text: [
      [b("저처럼 나이 많은 사람도 쓰기 쉽고,")],
      [n("수기로 전표 칠 때보다 "), h("일이 정확해져 "), n("만족스러워요.")],
    ],
    name: "왕* 물산 농산물 도매업",
    customerCategory: ["중도매인"],
  },
  {
    text: [
      [b("인터넷만 있으면 언제 어디서든")],
      [h("번거로운 설치 없이 "), n("업무 볼 수 있어서 좋아요.")],
    ],
    name: "가*** 유통 가락시장 중도매인",
    customerCategory: ["중도매인"],
  },
  {
    text: [
      [b("안팔리던 상품이 홍보"), n("가 되어 신규 주문도 증가하고")],
      [h("매출이 2배 "), n("늘었어요.")],
    ],
    name: "미** 종합 식자재 유통사",
    customerCategory: ["유통사"],
  },
  {
    text: [
      [b("여러 프랜차이즈의 통합 관리"), n("가 가능해져서")],
      [h("전산비용이 1/10"), n("로 줄었습니다.")],
    ],
    name: "라인** 프랜차이즈 물류대행사",
    customerCategory: ["프랜차이즈 본사"],
  },
  {
    text: [
      [b("가맹점 수가 20배 성장"), n("했음에도")],
      [n("수발주를 관리하는 "), h("직원은 한 명"), n("으로 충분해요.")],
    ],
    name: "에그*** 패스트푸드 프랜차이즈 본사",
    customerCategory: ["프랜차이즈 본사"],
  },
  {
    text: [
      [
        b("혼자 시작한 사업"),
        n("이 "),
        h("월 매출 8,000만원으로 성장"),
        n("할"),
      ],
      [n("때까지, 마켓봄이 "), b("핵심 기반"), n("이었어요.")],
    ],
    name: "데이**** 식품 제조 유통사",
    customerCategory: ["유통사"],
  },
  {
    text: [
      [n("직접 개발했으면 "), b("돈, 시간, 인력 계속 "), n("들어갔는데")],
      [h("월 8만원으로 발주 시스템 "), n("해결했죠.")],
    ],
    name: "카페** 무인카페 프랜차이즈 본사",
    customerCategory: ["프랜차이즈 본사"],
  },
  {
    text: [
      [b("식자재 중심의 정보"), n("가 절실했는데,")],
      [n("유통 신사업 "), h("1개월 만에 사업 관리 체계"), n("를 갖췄어요.")],
    ],
    name: "푸드*** 식자재 유통사",
    customerCategory: ["유통사"],
  },
  {
    text: [
      [h("5명도 안되는 본사 "), n("인력으로 할 게 너무 많지만,")],
      [b("적어도 수발주 처리 걱정은 없습니다.")],
    ],
    name: "스** 일식 프랜차이즈 본사",
    customerCategory: ["프랜차이즈 본사"],
  },
  {
    text: [
      [b("오주문도 줄이면서 미수금까지 잡으니까,")],
      [n("마켓봄이 저희의 "), h("사업 경쟁력"), n("이 되어줬죠.")],
    ],
    name: "한**** 종합 식자재 유통사",
    customerCategory: ["유통사"],
  },
  {
    text: [
      [b("직접 방문해서 설명"), n("해주니까")],
      [n("처음 써보는 사람도 "), h("어렵지 않아요.")],
    ],
    name: "서*상회 농산물 도매업",
    customerCategory: ["중도매인"],
  },
  {
    text: [
      [b("노트북과 포스기가 실시간으로 연동"), n("되고,")],
      [n("계산서 일괄 발행도 되니 "), h("정말 편리해요.")],
    ],
    name: "털*** 농산물 도소매",
    customerCategory: ["중도매인"],
  },
];
export const 리뷰_목록_모바일: 고객사_리뷰[] = [
  {
    text: [
      [
        b("저처럼 나이 많은 사람도 쓰기 쉽고, "),
        n("수기로 전표 칠 때보다 "),
        h("일이 정확해져 "),
        n("만족스러워요."),
      ],
    ],
    name: "왕* 물산 농산물 도매업",
    customerCategory: ["중도매인"],
  },
  {
    text: [
      [
        b("인터넷만 있으면 언제 어디서든 "),
        h("번거로운 설치 없이 "),
        n("업무 볼 수 있어서 좋아요."),
      ],
    ],
    name: "가*** 유통 가락시장 중도매인",
    customerCategory: ["중도매인"],
  },
  {
    text: [
      [
        b("안팔리던 상품이 홍보"),
        n("가 되어 신규 주문도 증가하고 "),
        h("매출이 2배 "),
        n("늘었어요."),
      ],
    ],
    name: "미** 종합 식자재 유통사",
    customerCategory: ["유통사"],
  },
  {
    text: [
      [
        b("여러 프랜차이즈의 통합 관리"),
        n("가 가능해져서 "),
        h("전산비용이 1/10"),
        n("로 줄었습니다."),
      ],
    ],
    name: "라인** 프랜차이즈 물류대행사",
    customerCategory: ["프랜차이즈 본사"],
  },
  {
    text: [
      [
        b("가맹점 수가 20배 성장"),
        n("했음에도 "),
        n("수발주를 관리하는 "),
        h("직원은 한 명"),
        n("으로 충분해요."),
      ],
    ],
    name: "에그*** 패스트푸드 프랜차이즈 본사",
    customerCategory: ["프랜차이즈 본사"],
  },
  {
    text: [
      [
        b("혼자 시작한 사업"),
        n("이 "),
        h("월 매출 8,000만원으로 성장"),
        n("할 "),
        n("때까지, 마켓봄이 "),
        b("핵심 기반"),
        n("이었어요."),
      ],
    ],
    name: "데이**** 식품 제조 유통사",
    customerCategory: ["유통사"],
  },
  {
    text: [
      [
        n("직접 개발했으면 "),
        b("돈, 시간, 인력 계속 "),
        n("들어갔는데 "),
        h("월 8만원으로 발주 시스템 "),
        n("해결했죠."),
      ],
    ],
    name: "카페** 무인카페 프랜차이즈 본사",
    customerCategory: ["프랜차이즈 본사"],
  },
  {
    text: [
      [
        b("식자재 중심의 정보"),
        n("가 절실했는데, "),
        n("유통 신사업 "),
        h("1개월 만에 사업 관리 체계"),
        n("를 갖췄어요."),
      ],
    ],
    name: "푸드*** 식자재 유통사",
    customerCategory: ["유통사"],
  },
  {
    text: [
      [
        h("5명도 안되는 본사 "),
        n("인력으로 할 게 너무 많지만, "),
        b("적어도 수발주 처리 걱정은 없습니다."),
      ],
    ],
    name: "스** 일식 프랜차이즈 본사",
    customerCategory: ["프랜차이즈 본사"],
  },
  {
    text: [
      [
        b("오주문도 줄이면서 미수금까지 잡으니까, "),
        n("마켓봄이 저희의 "),
        h("사업 경쟁력"),
        n("이 되어줬죠."),
      ],
    ],
    name: "한**** 종합 식자재 유통사",
    customerCategory: ["유통사"],
  },
  {
    text: [
      [
        b("직접 방문해서 설명"),
        n("해주니까 "),
        n("처음 써보는 사람도 "),
        h("어렵지 않아요."),
      ],
    ],
    name: "서*상회 농산물 도매업",
    customerCategory: ["중도매인"],
  },
  {
    text: [
      [
        b("노트북과 포스기가 실시간으로 연동"),
        n("되고, "),
        n("계산서 일괄 발행도 되니 "),
        h("정말 편리해요."),
      ],
    ],
    name: "털*** 농산물 도소매",
    customerCategory: ["중도매인"],
  },
];

import type { SceneScript } from "../Scene";
import { F, O } from "@mobily/ts-belt";

export const 수발주취합씬: SceneScript<"수발주취합"> = {
  playName: "수발주취합",
  scenes: [
    {
      Mobile: undefined,
      Desktop: {
        screen: "대시보드",
        location: ["600px", "300px"],
      },
      autoProceed: {
        delay: 2000,
        updateSceneState: F.identity,
      },
      zoom: 1,
      duration: "1000ms",
      alias: "수발주취합:Desktop:대시보드: 주문관리 메뉴 클릭",
      mainActor: "유통사",
      label: O.None,
    },
    {
      Mobile: undefined,
      Desktop: {
        screen: "주문목록",
        location: ["300px", "100px"],
      },
      autoProceed: {
        delay: 1000,
        updateSceneState: F.identity,
      },
      zoom: 0.58,
      duration: "1000ms",
      alias: "수발주취합:Desktop:주문목록: 새 주문 항목이 보여짐",
      mainActor: "유통사",
      label: O.None,
    },
    {
      Mobile: undefined,
      Desktop: {
        screen: "주문목록",
        location: ["300px", "100px"],
      },
      autoProceed: {
        delay: 2000,
        updateSceneState: F.identity,
      },
      zoom: 0.6,
      duration: "1000ms",
      alias: "수발주취합:Desktop:주문목록: 새 주문 항목이 보여짐",
      mainActor: "유통사",
      label: O.None,
    },
  ],
  initialSceneState: {
    냉동감자_개수: 1,
    데스크탑_주문관리_메뉴: "접힘",
    공지사항_문구: "",
    데스크탑_서비스관리_메뉴: "접힘",
    모바일_공지사항: "접힘",
    브랜드: undefined,
  },
};

import { useRef, useState, useEffect } from "react";
import { useCountAnimation } from "./IncreamentNumber";
import useOnScreen from "./util/useOnScreen";
import { 누적이용자수 } from "./데이터/누적가입자수";
import 섹션6_이미지 from "/public/assets/mobile/images/pic/img_main_section5.webp";

export const 랜딩_섹션6_Mobile = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [canStart, setCanStart] = useState(false);
  const firedOnce = useRef(false);
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    setCanStart(isVisible);
    firedOnce.current = true;
  }, [isVisible]);

  const 누적유통사 = useCountAnimation({
    startNumber: 0,
    endNumber: 누적이용자수.누적유통사,
    durationMS: 3000,
    canStart: firedOnce.current || canStart,
  });
  const 누적거래처 = useCountAnimation({
    startNumber: 0,
    endNumber: 누적이용자수.누적거래처,
    durationMS: 3000,
    canStart: firedOnce.current || canStart,
  });
  return (
    <div
      className="section section6"
      ref={ref}
      style={{
        background: `url(${섹션6_이미지.src}) no-repeat center bottom`,
        backgroundSize: "100% auto",
      }}
    >
      <h3>
        <div
          style={{
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "34px",
            letterSpacing: "-0.9px",
          }}
        >
          많은 프랜차이즈, 유통사들이 믿고 사용하는
        </div>
        <div
          style={{
            marginTop: "-4px",
            fontSize: "26px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "40px",
            letterSpacing: "-1.3px",
          }}
        >
          식자재 수발주 마켓봄
        </div>
      </h3>
      <dl>
        <dt>누적 유통사</dt>
        <dd className="counter" data-count="10577">
          <strong>{누적유통사}</strong>
        </dd>
      </dl>
      <dl>
        <dt>
          누적 거래처<span>(식당, 가맹점)</span>
        </dt>
        <dd className="counter" data-count="57577">
          <strong>{누적거래처}</strong>
        </dd>
      </dl>
    </div>
  );
};

import Image from "next/image";
import 유통사bg1 from "/public/assets/pc/images/animation/유통사bg.webp";
import 유통사1 from "/public/assets/pc/images/animation/유통사1.webp";
import 유통사말풍선1 from "/public/assets/pc/images/animation/유통사말풍선1.webp";
import 유통사말풍선2 from "/public/assets/pc/images/animation/유통사말풍선2.webp";
import 유통사말풍선3 from "/public/assets/pc/images/animation/유통사말풍선3.webp";
import 유통사2 from "/public/assets/pc/images/animation/유통사2.webp";
import 유통사3 from "/public/assets/pc/images/animation/유통사3.webp";
import 유통사4 from "/public/assets/pc/images/animation/유통사4.webp";

import 프랜차이즈1 from "/public/assets/pc/images/animation/프랜차이즈1.webp";
import 프랜차이즈2 from "/public/assets/pc/images/animation/프랜차이즈2.webp";
import 프랜차이즈3 from "/public/assets/pc/images/animation/프랜차이즈3.webp";
import 프랜차이즈말풍선1 from "/public/assets/pc/images/animation/프랜차이즈말풍선1.webp";
import 프랜차이즈말풍선2 from "/public/assets/pc/images/animation/프랜차이즈말풍선2.webp";
import 프랜차이즈말풍선3 from "/public/assets/pc/images/animation/프랜차이즈말풍선3.webp";

import 중도매인1 from "/public/assets/pc/images/animation/중도매인1.webp";
import 중도매인2 from "/public/assets/pc/images/animation/중도매인2.webp";
import 중도매인3 from "/public/assets/pc/images/animation/중도매인3.webp";
import 중도매인4 from "/public/assets/pc/images/animation/중도매인4.webp";
import 중도매인5 from "/public/assets/pc/images/animation/중도매인5.webp";
import 중도매인말풍선1 from "/public/assets/pc/images/animation/중도매인말풍선1.webp";
import 중도매인말풍선2 from "/public/assets/pc/images/animation/중도매인말풍선2.webp";
import 중도매인말풍선3 from "/public/assets/pc/images/animation/중도매인말풍선3.webp";

import styles from "./랜딩_섹션1_Desktop3_Animation.module.css";
import { asx } from "./util/applyStyle";
import { useContext, useEffect, useState } from "react";
import { CustomerCategoryCTX } from "./util/CustomerCategoryCTX";
const as = asx(styles);

type AnimationSceneProps = {
  visible: boolean;
};
const 유통사Animation = ({ visible }: AnimationSceneProps) => {
  const visibleClassName = visible ? "on" : "off";
  return (
    <div className={as(`section ${visibleClassName}`)}>
      <Image
        src={유통사bg1}
        alt={""}
        priority
        fetchPriority={"high"}
        className={as(`ytsBg1 ${visibleClassName}`)}
      />
      <div className={as(`yts1 ${visibleClassName}`)}>
        <Image
          src={유통사1}
          alt={""}
          width={453}
          priority
          fetchPriority={"high"}
          className={as(`ghost ${visibleClassName}`)}
        />
        <Image
          src={유통사말풍선1}
          alt={""}
          className={as(`ytsbubble1 ${visibleClassName}`)}
          priority
          fetchPriority={"high"}
          width={173}
        />
      </div>
      <div className={as(`yts2 ${visibleClassName}`)}>
        <Image
          src={유통사2}
          alt={""}
          width={508}
          className={as(`ghost ${visibleClassName}`)}
          priority
          fetchPriority={"high"}
        />
        <Image
          src={유통사말풍선2}
          alt={""}
          className={as(`ytsbubble2 ${visibleClassName}`)}
          priority
          fetchPriority={"high"}
          width={173}
        />
      </div>
      <div className={as(`yts3 ${visibleClassName}`)}>
        <Image
          src={유통사3}
          alt={""}
          width={215}
          priority
          fetchPriority={"high"}
          className={as(`ghost ${visibleClassName}`)}
        />
      </div>
      <div className={as(`yts4 ${visibleClassName}`)}>
        <Image
          src={유통사4}
          alt={""}
          width={263}
          priority
          fetchPriority={"high"}
          className={as(`ghost ${visibleClassName}`)}
        />
        <Image
          src={유통사말풍선3}
          alt={""}
          priority
          fetchPriority={"high"}
          className={as(`ytsbubble3 ${visibleClassName}`)}
          width={173}
        />
      </div>
    </div>
  );
};

const 프랜차이즈본사Animation = ({ visible }: AnimationSceneProps) => {
  const visibleClassName = visible ? "on" : "off";
  return (
    <div className={as(`section ${visibleClassName}`)}>
      <Image
        src={유통사bg1}
        alt={""}
        className={as(`frchBg1 ${visibleClassName}`)}
        width={546}
        priority
      />
      <div className={as(`frch1 ${visibleClassName}`)}>
        <Image
          src={프랜차이즈1}
          alt={""}
          width={453}
          className={as(`ghost ${visibleClassName}`)}
          priority
        />
        <Image
          src={프랜차이즈말풍선1}
          alt={""}
          className={as(`frchbubble1 ${visibleClassName}`)}
          width={213}
          priority
        />
      </div>
      <div className={as(`frch2 ${visibleClassName}`)}>
        <Image
          src={프랜차이즈2}
          alt={""}
          width={280}
          priority
          className={as(`ghost ${visibleClassName}`)}
        />
        <Image
          src={프랜차이즈말풍선2}
          alt={""}
          className={as(`frchbubble2 ${visibleClassName}`)}
          width={173}
          priority
        />
      </div>
      <div className={as(`frch3 ${visibleClassName}`)}>
        <Image
          src={프랜차이즈3}
          alt={""}
          width={280}
          priority
          className={as(`ghost ${visibleClassName}`)}
        />
        <Image
          src={프랜차이즈말풍선3}
          alt={""}
          className={as(`frchbubble3 ${visibleClassName}`)}
          width={173}
          priority
        />
      </div>
    </div>
  );
};

const 중도매인Animation = ({ visible }: AnimationSceneProps) => {
  const visibleClassName = visible ? "on" : "off";
  return (
    <div className={as(`section ${visibleClassName}`)}>
      <div className={as(`jdmi1 ${visibleClassName}`)}>
        <Image
          src={중도매인1}
          alt={""}
          width={627}
          priority
          className={as(`ghost ${visibleClassName}`)}
        />
        <Image
          src={중도매인말풍선1}
          alt={""}
          className={as(`jdmibubble1 ${visibleClassName}`)}
          width={173}
          priority
        />
      </div>
      <div className={as(`jdmi2 ${visibleClassName}`)}>
        <Image
          src={중도매인2}
          alt={""}
          width={227}
          priority
          className={as(`ghost ${visibleClassName}`)}
        />
        <Image
          src={중도매인말풍선2}
          alt={""}
          className={as(`jdmibubble2 ${visibleClassName}`)}
          width={173}
          priority
        />
      </div>
      <div className={as(`jdmi3 ${visibleClassName}`)}>
        <Image
          src={중도매인3}
          alt={""}
          width={280}
          priority
          className={as(`receipt receipt1 ${visibleClassName}`)}
        />
        <Image
          src={중도매인4}
          alt={""}
          width={240}
          priority
          className={as(`receipt receipt2 ${visibleClassName}`)}
        />
        <Image
          src={중도매인5}
          alt={""}
          width={280}
          priority
          className={as(`receipt receipt3 ${visibleClassName}`)}
        />
        <Image
          src={중도매인말풍선3}
          alt={""}
          className={as(`jdmibubble3 ${visibleClassName}`)}
          width={173}
          priority
        />
      </div>
    </div>
  );
};

export const UISection = () => {
  const [currentCustomerCategory, setCurrentCustomerCategory] = useState("");
  const { customerCategory } = useContext(CustomerCategoryCTX);

  useEffect(() => {
    const initialTimer = setTimeout(
      () => setCurrentCustomerCategory(customerCategory),
      500
    );
    return () => {
      clearTimeout(initialTimer);
    };
  }, [customerCategory]);

  useEffect(() => {
    setCurrentCustomerCategory(customerCategory);
  }, [customerCategory]);

  return (
    <div
      style={{
        position: "absolute",
        width: "1180px",
        height: "531px",
        marginTop: "312px",
        overflow: "hidden",
        background: "",
      }}
    >
      <유통사Animation visible={currentCustomerCategory === "유통사"} />
      <프랜차이즈본사Animation
        visible={currentCustomerCategory === "프랜차이즈 본사"}
      />
      <중도매인Animation visible={currentCustomerCategory === "중도매인"} />
    </div>
  );
};

import { asx } from "../../util/applyStyle";
import styles from "./MockMarketbom.module.css";

const as = asx(styles);
export const MockDashBoard = () => {
  return (
    <>
      <div className={as("notice")}>
        <div className={as("title")}>{"공지사항"}</div>
        <div className={as("bar")}>
          {"[공지] 바우처 3차 모집, 최대 86% 할인 (~8/14 오후 6시 마감)"}
        </div>
      </div>
      <div style={{ display: "flex", gap: "40px", marginBottom: "52px" }}>
        <div className={as("dashboardContent")}>
          <div className={as("title")}>판매 진행 현황 (한달기준)</div>
          <div className={as("boxRow")}>
            <div className={as("box 출고전")}>
              <span>출고전</span>
              <span className={as("num")}>4</span>
            </div>
            <div className={as("box 출고완료")}>
              <span>출고완료</span>
              <span className={as("num")}>0</span>
            </div>
            <div className={as("box 출고거절")}>
              <span>출고거절</span>
              <span className={as("num")}>0</span>
            </div>
          </div>
        </div>
        <div className={as("dashboardContent")}>
          <div className={as("title")}>체크항목</div>
          <div className={as("checkList")}>
            <div className={as("checkItem")}>
              <div className={as("name field")}>{"재고부족(1~100개)"}</div>
              <div className={as("value field")}>{"5 건"}</div>
            </div>
            <div className={as("checkItem")}>
              <div className={as("name field")}>{"재고없음(0개)"}</div>
              <div className={as("value field")}>{"2 건"}</div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", gap: "40px" }}>
        <div className={as("dashboardContent")}>
          <div className={as("title")}>주문현황</div>
          <div className={as("주문현황")}>
            <div className={as("item")}>
              <div className={as("sectionName")}>{"당일"}</div>
              <div className={as("sectionContent")}>
                <div className={as("name")}>{"주문 4건"}</div>
                <div className={as("value")}>{"1,937,610 원"}</div>
              </div>
              <div className={as("sectionContent2")}>
                <div className={as("name")}>{"취소 0건"}</div>
                <div className={as("value")}>{"0 원"}</div>
              </div>
            </div>
            <div className={as("item")}>
              <div className={as("sectionName")}>{"한달"}</div>
              <div className={as("sectionContent")}>
                <div className={as("name")}>{"주문 63건"}</div>
                <div className={as("value")}>{"17,926,320 원"}</div>
              </div>
              <div className={as("sectionContent2")}>
                <div className={as("name")}>{"취소 3건"}</div>
                <div className={as("value")}>{"-142,040 원"}</div>
              </div>
            </div>
          </div>
        </div>

        <div className={as("dashboardContent")}>
          <div className={as("title")}>고객문의 및 공지관리</div>
          <div className={as("공지탭_list")}>
            <div className={as("공지탭_row")}>
              <div className={as("공지탭 on")}>고객문의</div>
              <div className={as("공지탭 off")}>공지사항</div>
            </div>
            <div className={as("공지_list")}>
              <div className={as("item")}>
                <div className={as("name")}>
                  [공지] 다음 주 계란 특가 선주문 받습니다.
                </div>
                <div className={as("timestamp")}>2023-02-15</div>
              </div>
              <div className={as("item")}>
                <div className={as("name")}>[공지] 택배사 휴무 공지</div>
                <div className={as("timestamp")}>2022-08-19</div>
              </div>
              <div className={as("item")}>
                <div className={as("name")}>
                  [공지] 바우처 3차 모집 최대 86% 할인
                </div>
                <div className={as("timestamp")}>2022-08-19</div>
              </div>
              <div className={as("item")}>
                <div className={as("name")}>
                  [공지] 마켓봄 프로그램 가이드 다운로드
                </div>
                <div className={as("timestamp")}>2020-11-19</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

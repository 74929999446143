"use client";

import Image from "next/image";
import { useState } from "react";
import { useTracker } from "./util/Tracker";
import { 마켓봄피쳐_목록, type 마켓봄피쳐 } from "./데이터/마켓봄피쳐";
import { BoldableTextList, BoldableTextMultiline } from "./BoldableText";

type 섹션Props = {
  마켓봄피쳐: 마켓봄피쳐;
};

const 섹션3_Row = ({ 마켓봄피쳐 }: 섹션Props) => {
  const {
    제목: { 단점, 장점 },
    설명,
    이미지,
  } = 마켓봄피쳐_목록()[마켓봄피쳐];

  return (
    <div className="white-box">
      <h4>
        <BoldableTextList list={단점} />
        <br />
        <span className="fc1">
          <BoldableTextList list={장점} />
        </span>
      </h4>
      <div style={{ margin: "0px auto", width: "fit-content" }}>
        <BoldableTextMultiline list={설명} align="center" />
      </div>
      <div className="pic">
        <Image src={이미지} width={540} alt="" />
      </div>
    </div>
  );
};

export const 마켓봄_장점_Mobile = () => {
  const tracker = useTracker();
  const [foldState, setFoldState] = useState(false);
  return (
    <div className="section section3">
      <div
        style={{
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "34px",
          letterSpacing: "-0.9px",
        }}
      >
        아직도 마켓봄 도입을 망설이고 계신가요?
      </div>
      <h3 style={{ fontSize: "28px" }}>마켓봄, 이런 장점도 있어요!</h3>
      <섹션3_Row 마켓봄피쳐="유통사앱즉시확인" />
      <섹션3_Row 마켓봄피쳐="언제어디서나" />
      <섹션3_Row 마켓봄피쳐="원격세팅지원" />
      {foldState && (
        <div className="toggle-wrap">
          <섹션3_Row 마켓봄피쳐="거래처주문앱" />
          <섹션3_Row 마켓봄피쳐="미수금0" />
          <섹션3_Row 마켓봄피쳐="무제한추가" />
          <섹션3_Row 마켓봄피쳐="맞춤상품" />
          <섹션3_Row 마켓봄피쳐="거래처별원클릭단가" />
          <섹션3_Row 마켓봄피쳐="자동취합" />
        </div>
      )}
      <button
        type="button"
        className="btn-detail"
        onClick={() => {
          setFoldState(!foldState);
          tracker.trackingClick({ depth1: "HOME", depth2: "문제점알아보기" });
        }}
      >
        {foldState ? "접기" : "다른 문제점 알아보기"}
      </button>
    </div>
  );
};

import 메인_이미지 from "/public/assets/mobile/images/pic/img_main_section1_3.png";
import styles from "./랜딩_섹션1_Mobile3.module.css";
import { asx } from "./util/applyStyle";
import Image, { type StaticImageData } from "next/image";
import {
  CUSTOMTER_CATEGORY,
  CustomerCategoryCTX,
  type CustomerCategory,
} from "./util/CustomerCategoryCTX";
import { useContext, type CSSProperties } from "react";
import {
  normalText,
  BoldableText,
  boldText,
  BoldableTextMultiline,
} from "./BoldableText";

import 유통사1 from "/public/assets/mobile/images/pic/유통사1.png";
import 유통사2 from "/public/assets/mobile/images/pic/유통사2.png";
import 유통사3 from "/public/assets/mobile/images/pic/유통사3.png";

import 프랜차이즈1 from "/public/assets/mobile/images/pic/프랜차이즈1.png";
import 프랜차이즈2 from "/public/assets/mobile/images/pic/프랜차이즈2.png";
import 프랜차이즈3 from "/public/assets/mobile/images/pic/프랜차이즈3.png";

import 중도매인1 from "/public/assets/mobile/images/pic/중도매인1.png";
import 중도매인2 from "/public/assets/mobile/images/pic/중도매인2.png";
import 중도매인3 from "/public/assets/mobile/images/pic/중도매인3.png";

import { match } from "ts-pattern";

const as = asx(styles);
type CustomerCategoryToggleProps = {
  targetCustomerCategory: CustomerCategory;
  onClick: (cc: CustomerCategory) => void;
  selected: boolean;
};
const CustomerCategoryToggle = ({
  targetCustomerCategory,
  onClick,
  selected,
}: CustomerCategoryToggleProps) => {
  return (
    <button
      onClick={() => onClick(targetCustomerCategory)}
      style={{
        padding: "4px 12px 6px 12px",
        fontSize: "16px",
        fontWeight: "bold",
        borderRadius: "20px",
        boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.15)",
        transition: "all 0.5s cubic-bezier(0.2,0.8,0.2,1)",
        ...(selected
          ? {
            border: "2px solid #533B85",
            background: "#533B85",
            color: "white",
          }
          : {
            color: "rgba(83, 59, 133, 0.50)",
            border: "2px solid transparent",
            background: "rgba(255, 255, 255, 0.30)",
          }),
      }}
    >
      {targetCustomerCategory}
    </button>
  );
};

type CustomerCategoryToggleGroupProps = {
  currentValue: CustomerCategory;
  onClick: (cc: CustomerCategory) => void;
};

const CustomerCategoryToggleGroup = ({
  currentValue,
  onClick,
}: CustomerCategoryToggleGroupProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "8px",
        alignItems: "center",
        marginBottom: "16px",
        justifyContent: "center",
        WebkitTapHighlightColor: "transparent",
      }}
    >
      {CUSTOMTER_CATEGORY.map((cc) => (
        <CustomerCategoryToggle
          targetCustomerCategory={cc}
          onClick={onClick}
          selected={cc === currentValue}
          key={cc}
        />
      ))}
    </div>
  );
};

type ContentCardProps = {
  text: BoldableText[][];
  image: StaticImageData;
  imagePos: "contain" | [number, number];
};

const ContentCard = ({ text, image, imagePos }: ContentCardProps) => {
  const style_: CSSProperties =
    imagePos === "contain"
      ? {
        width: "100%",
        objectFit: "contain",
        maxWidth: "320px",
      }
      : {
        width: "100%",
        objectFit: "contain",
        height: "fit-content",
        transform: `translateX(${imagePos[0]}px) translateY(${imagePos[1]}px)`,
        willChange: "transform",
      };
  return (
    <div
      style={{
        width: "calc(100% - 48px)",
        maxHeight: "360px",
        margin: "0px 24px",
        paddingTop: "24px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "rgba(255, 255, 255, 0.3)",
        borderRadius: "18px",
        fontSize: "22px",
        overflow: "hidden",

        boxShadow: "0px 4px 10px 0px rgba(38, 29, 72, 0.12)",
        backdropFilter: "blur(5px)",
      }}
    >
      <BoldableTextMultiline
        list={text}
        align="center"
        style={{ marginBottom: "32px" }}
      />
      <div style={{ flex: 1, maxHeight: "32px" }} />
      <Image
        src={image}
        alt=""
        style={{ ...style_, height: "300px" }}
        height={300}
        fetchPriority="high"
        priority
        loading={"eager"}
      />
    </div>
  );
};

const 유통사 = () => {
  return (
    <>
      <ContentCard
        text={[[n("거래처도 만족하는")], [b("쉬운 앱 발주")]]}
        image={유통사1}
        imagePos="contain"
      />
      <ContentCard
        text={[[n("선결제로")], [b("미수금 문제 해결")]]}
        image={유통사2}
        imagePos={"contain"}
      />
      <ContentCard
        text={[[n("쉽고 편한")], [b("특단가 관리")]]}
        image={유통사3}
        imagePos={"contain"}
      />
    </>
  );
};
const 프랜차이즈 = () => {
  return (
    <>
      <ContentCard
        text={[[n("가맹점이 많아져도")], [b("추가비용 무료")]]}
        image={프랜차이즈1}
        imagePos={"contain"}
      />
      <ContentCard
        text={[[b("브랜드별 전략 상품")], [b("상위 노출"), n("로 매출 증가")]]}
        image={프랜차이즈2}
        imagePos={"contain"}
      />
      <ContentCard
        text={[[b("가맹점 공지"), n("도")], [n("한 번에 손쉽게!")]]}
        image={프랜차이즈3}
        imagePos={"contain"}
      />
    </>
  );
};

const 중도매인 = () => {
  return (
    <>
      <ContentCard
        text={[[b("POS 전용 화면"), n("으로")], [n("매출/매입 관리")]]}
        image={중도매인1}
        imagePos={"contain"}
      />
      <ContentCard
        text={[[b("모바일"), n("로 현장에서")], [n("즉시 판매 등록")]]}
        image={중도매인2}
        imagePos={"contain"}
      />
      <ContentCard
        text={[[n("판매 등록하면")], [b("영수증, 전표도 자동"), n("으로!")]]}
        image={중도매인3}
        imagePos={"contain"}
      />
    </>
  );
};

const n = normalText;
const b = boldText;

export const 랜딩_섹션1_Mobile = () => {
  const { customerCategory, setCustomerCategory } =
    useContext(CustomerCategoryCTX);

  return (
    <>
      <div
        className={as("section section1")}
        style={{
          background:
            "linear-gradient(114deg, rgba(141, 139, 240, 0.50) 5.91%, rgba(255, 110, 79, 0.50) 157.75%)",
          backgroundSize: "100% auto",
        }}
      >
        <p>
          세상에서 제일 간편한
          <br />
          수발주 마켓봄
        </p>
        <Image
          src={메인_이미지}
          alt=""
          priority
          style={{ width: "100%", padding: "0px 24px", marginBottom: "32px" }}
        />
        <CustomerCategoryToggleGroup
          currentValue={customerCategory}
          onClick={(newValue) => {
            setCustomerCategory(newValue);
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          {match(customerCategory)
            .with("유통사", () => <유통사 />)
            .with("프랜차이즈 본사", () => <프랜차이즈 />)
            .with("중도매인", () => <중도매인 />)
            .exhaustive()}
        </div>
      </div>
    </>
  );
};

import { 헤더_DesktopAB } from "./헤더_DesktopAB";
import { 마켓봄_업데이트_소식 } from "./마켓봄_업데이트_소식_Desktop3";
import {
  랜딩_섹션2_Desktop,
  랜딩_섹션2_Desktop_Skeleton,
} from "./랜딩_섹션2_Desktop3";
import { 랜딩_섹션3_Desktop } from "./랜딩_섹션3_Desktop3";
import { 랜딩_섹션4_Desktop } from "./랜딩_섹션4_Desktop3";
import { 랜딩_섹션5_Desktop } from "./랜딩_섹션5_Desktop3";
import {
  랜딩_섹션6_Desktop,
  랜딩_섹션6_Desktop_Skeleton,
} from "./랜딩_섹션6_Desktop";

import { 랜딩_섹션9_Desktop } from "./랜딩_섹션9_Desktop3";
import { 푸터_Desktop } from "./푸터_Desktop";
import { CSR } from "./CSR";
import { CTA_간편가입_Desktop } from "./CTA_간편가입_Desktop3";
import { 랜딩_섹션1_Desktop3 } from "./랜딩_섹션1_Desktop3";
import 랜딩_고객사_목록_Desktop from "./랜딩_고객사_목록_Desktop";
import {
  랜딩_애니메이션_Desktop,
  랜딩_애니메이션_Desktop_Skeleton,
} from "./랜딩_애니메이션_Desktop3";
import {
  CustomerCategoryCTX,
  type CustomerCategory,
} from "./util/CustomerCategoryCTX";
import { useState } from "react";

const 랜딩_PC = () => {
  const [customerCategory, setCustomerCategory] =
    useState<CustomerCategory>("유통사");
  return (
    <>
      <CustomerCategoryCTX.Provider
        value={{ customerCategory, setCustomerCategory }}
      >
        <div id="wrap">
          <헤더_DesktopAB />
          <div id="container" className="main">
            <랜딩_섹션1_Desktop3 />
            <CSR fallback={<랜딩_애니메이션_Desktop_Skeleton />}>
              <랜딩_애니메이션_Desktop />
            </CSR>
            <CSR fallback={<랜딩_섹션2_Desktop_Skeleton />}>
              <랜딩_섹션2_Desktop />
            </CSR>
            <CSR fallback={<랜딩_섹션6_Desktop_Skeleton />}>
              <랜딩_섹션6_Desktop />
            </CSR>
            <랜딩_고객사_목록_Desktop />
            <랜딩_섹션3_Desktop />
            <랜딩_섹션4_Desktop />
            <랜딩_섹션5_Desktop />
            <마켓봄_업데이트_소식 />
            <랜딩_섹션9_Desktop />
          </div>
          <푸터_Desktop />
          <CTA_간편가입_Desktop />
        </div>
      </CustomerCategoryCTX.Provider>
    </>
  );
};
export default 랜딩_PC;

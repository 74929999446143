import { O } from "@mobily/ts-belt";
import { type SceneScript } from "../Scene";

export const 공지씬: SceneScript<"공지"> = {
  playName: "공지",
  scenes: [
    {
      Mobile: {
        screen: "메인홈",
        location: ["-200px", "30%"],
      },
      Desktop: O.None,
      autoProceed: {
        delay: 2000,
        updateSceneState: O.None,
      },
      alias: "공지:Mobile:메인홈: 메인홈",
      duration: "1000ms",
      mainActor: "유통사",
      label: O.None,
    },
    {
      Mobile: {
        screen: "메인홈",
        location: ["-200px", "30%"],
      },
      Desktop: O.None,
      autoProceed: {
        delay: 2000,
        updateSceneState: O.None,
      },
      alias: "공지:Mobile:메인홈: 모달 보여주기",
      duration: "1000ms",
      mainActor: "유통사",
      label: O.None,
      zoom: 0.9,
    },
    {
      Mobile: {
        screen: "메인홈",
        location: ["-200px", "30%"],
      },
      Desktop: undefined,
      autoProceed: {
        delay: 2000,
        updateSceneState: O.None,
      },
      alias: "공지:Mobile:메인홈: 공지사항 보여주기",
      duration: "1000ms",
      mainActor: "유통사",
      label: O.None,
    },
  ],
  initialSceneState: {
    냉동감자_개수: 1,
    데스크탑_주문관리_메뉴: "접힘",
    공지사항_문구: "",
    데스크탑_서비스관리_메뉴: "접힘",
    모바일_공지사항: "접힘",
    브랜드: undefined,
  },
};

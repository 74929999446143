import Image, { type StaticImageData } from "next/image";
import { A, D, pipe } from "@mobily/ts-belt";
import styles from "./랜딩_고객사_목록_Desktop.module.css";

import { asx } from "./util/applyStyle";

const as = asx(styles);
import logo1 from "/public/assets/pc/images/pic/customerLogos/Frame 27434-1.webp";
import logo2 from "/public/assets/pc/images/pic/customerLogos/Frame 27434.webp";
import logo3 from "/public/assets/pc/images/pic/customerLogos/Frame 27435-1.webp";
import logo4 from "/public/assets/pc/images/pic/customerLogos/Frame 27435.webp";
import logo5 from "/public/assets/pc/images/pic/customerLogos/Frame 27436-1.webp";
import logo6 from "/public/assets/pc/images/pic/customerLogos/Frame 27436.webp";
import logo7 from "/public/assets/pc/images/pic/customerLogos/Frame 27437-1.webp";
import logo8 from "/public/assets/pc/images/pic/customerLogos/Frame 27437.webp";
import logo9 from "/public/assets/pc/images/pic/customerLogos/Frame 27438-1.webp";
import logo10 from "/public/assets/pc/images/pic/customerLogos/Frame 27438.webp";
import logo11 from "/public/assets/pc/images/pic/customerLogos/Frame 27439-1.webp";
import logo12 from "/public/assets/pc/images/pic/customerLogos/Frame 27439.webp";
import logo13 from "/public/assets/pc/images/pic/customerLogos/Frame 27440-1.webp";
import logo14 from "/public/assets/pc/images/pic/customerLogos/Frame 27440.webp";
import logo15 from "/public/assets/pc/images/pic/customerLogos/Frame 27441-1.webp";
import logo16 from "/public/assets/pc/images/pic/customerLogos/Frame 27441.webp";
import logo17 from "/public/assets/pc/images/pic/customerLogos/Frame 27442-1.webp";
import logo18 from "/public/assets/pc/images/pic/customerLogos/Frame 27442.webp";
import logo19 from "/public/assets/pc/images/pic/customerLogos/Frame 27443-1.webp";
import logo20 from "/public/assets/pc/images/pic/customerLogos/Frame 27443.webp";
import logo21 from "/public/assets/pc/images/pic/customerLogos/Frame 27444-1.webp";
import logo22 from "/public/assets/pc/images/pic/customerLogos/Frame 27444.webp";
import logo23 from "/public/assets/pc/images/pic/customerLogos/Frame 27445-1.webp";
import logo24 from "/public/assets/pc/images/pic/customerLogos/Frame 27445.webp";
import logo25 from "/public/assets/pc/images/pic/customerLogos/Frame 27446-1.webp";
import logo26 from "/public/assets/pc/images/pic/customerLogos/Frame 27446.webp";
import logo27 from "/public/assets/pc/images/pic/customerLogos/Frame 27447-1.webp";
import logo28 from "/public/assets/pc/images/pic/customerLogos/Frame 27447-2.webp";
import logo29 from "/public/assets/pc/images/pic/customerLogos/Frame 27447-3.webp";
import logo30 from "/public/assets/pc/images/pic/customerLogos/Frame 27447.webp";
import logo31 from "/public/assets/pc/images/pic/customerLogos/Frame 27448-1.webp";
import logo32 from "/public/assets/pc/images/pic/customerLogos/Frame 27448-2.webp";
import logo33 from "/public/assets/pc/images/pic/customerLogos/Frame 27448-3.webp";
import logo34 from "/public/assets/pc/images/pic/customerLogos/Frame 27448.webp";
import logo35 from "/public/assets/pc/images/pic/customerLogos/Frame 27449-1.webp";
import logo36 from "/public/assets/pc/images/pic/customerLogos/Frame 27449.webp";
import logo37 from "/public/assets/pc/images/pic/customerLogos/Frame 27450-1.webp";
import logo38 from "/public/assets/pc/images/pic/customerLogos/Frame 27450.webp";
import logo39 from "/public/assets/pc/images/pic/customerLogos/Frame 27451-1.webp";
import logo40 from "/public/assets/pc/images/pic/customerLogos/Frame 27451.webp";
import logo41 from "/public/assets/pc/images/pic/customerLogos/Frame 27452-1.webp";
import logo42 from "/public/assets/pc/images/pic/customerLogos/Frame 27452.webp";
import logo43 from "/public/assets/pc/images/pic/customerLogos/Frame 27453-1.webp";
import logo44 from "/public/assets/pc/images/pic/customerLogos/Frame 27453.webp";
import logo45 from "/public/assets/pc/images/pic/customerLogos/Frame 27454-1.webp";
import logo46 from "/public/assets/pc/images/pic/customerLogos/Frame 27454.webp";
import logo47 from "/public/assets/pc/images/pic/customerLogos/Frame 27455-1.webp";
import logo48 from "/public/assets/pc/images/pic/customerLogos/Frame 27455.webp";
import logo49 from "/public/assets/pc/images/pic/customerLogos/Frame 27456.webp";
import logo50 from "/public/assets/pc/images/pic/customerLogos/Frame 27457.webp";
import logo51 from "/public/assets/pc/images/pic/customerLogos/Frame 27458.webp";
import logo52 from "/public/assets/pc/images/pic/customerLogos/Frame 27459.webp";
import logo53 from "/public/assets/pc/images/pic/customerLogos/Frame 27460.webp";

const logoMap: Record<string, StaticImageData> = {
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo10,
  logo11,
  logo12,
  logo13,
  logo14,
  logo15,
  logo16,
  logo17,
  logo18,
  logo19,
  logo20,
  logo21,
  logo22,
  logo23,
  logo24,
  logo25,
  logo26,
  logo27,
  logo28,
  logo29,
  logo30,
  logo31,
  logo32,
  logo33,
  logo34,
  logo35,
  logo36,
  logo37,
  logo38,
  logo39,
  logo40,
  logo41,
  logo42,
  logo43,
  logo44,
  logo45,
  logo46,
  logo47,
  logo48,
  logo49,
  logo50,
  logo51,
  logo52,
  logo53,
};

const 고객_로고 = ({ 로고, 이름 }: { 로고: StaticImageData; 이름: string }) => {
  return (
    <div
      style={{
        width: "170px",
        height: "70px",
        borderRadius: "10px",
        background: "#FFF",
        boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.15)",
        overflow: "hidden",
      }}
    >
      <Image src={로고} alt={이름} quality={100} width={177} />
    </div>
  );
};

const 랜딩_고객사_목록_Desktop = () => {
  return (
    <div
      style={{
        marginTop: "-1px",
        padding: "65px 0px",
        background: "#533B85",
      }}
    >
      <div style={{ overflow: "hidden", width: "1180px", margin: "0px auto" }}>
        <div
          style={{ display: "inline-flex", gap: "24px" }}
          className={as("slide")}
        >
          {pipe(
            logoMap,
            D.toPairs,
            A.map(([이름, 로고]) => (
              <고객_로고 로고={로고} 이름={이름} key={이름} />
            ))
          )}
          {pipe(
            logoMap,
            D.toPairs,
            A.take(7),
            A.map(([이름, 로고]) => (
              <고객_로고 로고={로고} 이름={이름} key={이름 + "2"} />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default 랜딩_고객사_목록_Desktop;
